<template>
  <div>
    <template v-if="editionMode">
      <div :key="internalValue" class="relative" :class="label ? 'mt-2' : ''">
        <label
          v-if="label"
          :for="name"
          class="absolute -top-2 left-2 z-10 -mt-px inline-block h-3.5 rounded-md bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
          >{{ label }}
          <span v-if="label && required" class="text-red-600">&nbsp;*</span>
        </label>
        <div
          class="inline-flex divide-x overflow-hidden rounded-md border border-slate-300 shadow-sm dark:border dark:border-white"
          role="group"
        >
          <button
            v-for="(option, index) in dynamicOptions"
            :key="index"
            type="button"
            :class="[
              option[trackBy] === internalValue
                ? 'bg-primary-500 text-white'
                : 'bg-white text-gray-900'
            ]"
            class="px-4 py-2 text-sm font-medium capitalize-first"
            @click="() => (internalValue = option[trackBy])"
          >
            {{ $t(`enums.yesNo.${option[attributeLabel]}`) }}
          </button>
        </div>
      </div>
      <ErrorContainer :error-key="name" :errors="errors" />
    </template>
    <template v-else>
      <BaseShowLabel
        :label="label"
        :model-value="displaydValueWhenNotInEditMode"
      />
    </template>
  </div>
</template>

<script>
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'

export default {
  name: 'BaseShowEditUndefinedRadioGroup',
  components: { BaseShowLabel, ErrorContainer },
  props: {
    modelValue: {
      type: [Boolean, Number, String],
      required: false,
      default: null
    },
    name: { type: String, required: true },
    label: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    extraId: { type: String, required: false, default: '' },
    errors: { type: Object, required: false, default: null },
    isVertical: { type: Boolean, required: false, default: false },
    editionMode: {
      type: Boolean,
      required: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'value'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'label'
    },
    withUndefined: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      options: [
        {
          value: 1,
          label: true
        },
        {
          value: 0,
          label: false
        },
        {
          value: null,
          label: 'undefined'
        }
      ]
    }
  },
  computed: {
    dynamicOptions() {
      return this.options.filter(
        (option) => option.label !== 'undefined' || this.withUndefined
      )
    },
    temp_name() {
      return `${this.name}_${this.extraId}`
    },
    internalValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
        this.$emit('change', value ?? null)
      }
    },
    cPlacement() {
      return this.isVertical ? '' : 'flex flex-col sm:flex-row mt-2'
    },
    displaydValueWhenNotInEditMode() {
      if (this.internalValue === null) {
        return this.$t(`enums.yesNo.undefined`)
      }
      return this.$t(`enums.yesNo.${Boolean(this.internalValue)}`)
    }
  }
}
</script>
