<template>
  <nav class="flex flex-row">
    <div class="flex flex-1 flex-row gap-2 overflow-x-scroll">
      <button
        v-for="(category, idx) in filteredCategories"
        :key="idx"
        class="items-center whitespace-nowrap rounded-md border-b-2 px-1 pt-1 text-sm font-bold text-gray-500 dark:bg-slate-700 dark:text-white"
        :class="
          selectedCategory === category.id
            ? 'border-theme-primary dark:border-theme-faded'
            : 'border-transparent font-medium hover:border-theme-primary hover:text-gray-900  dark:hover:bg-gray-300 dark:hover:text-slate-700'
        "
        @click="() => (localSelectedCategory = category.id)"
      >
        {{ category.name }}
      </button>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CatalogNavigationComponent',
  props: {
    categories: {
      type: Array,
      required: true
    },
    selectItem: {
      type: Object,
      required: true
    },
    selectedCategory: {
      type: Object,
      required: true
    },
    filter: {
      type: Object,
      required: true
    },
    isCatalogModalOpen: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:selectedCategory'],
  computed: {
    ...mapGetters({
      allCategories: 'metadata/getMetadataItemCategories'
    }),
    localSelectedCategory: {
      get() {
        return this.selectedCategory
      },
      set(value) {
        this.$emit('update:selectedCategory', value)
      }
    },
    filteredCategories() {
      if (!this.categories) {
        return []
      }
      return this.allCategories.filter((item) =>
        this.categories.includes(item.id)
      )
    }
  }
}
</script>
