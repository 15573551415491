<template>
  <template v-if="editionMode">
    <div
      class="relative rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
      :class="label ? 'mt-2' : ''"
    >
      <label
        v-if="label"
        :for="name"
        class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
        >
        {{ label }}&nbsp;
        <span v-if="label && required" class="text-red-600">*</span>
      </label>
      <div class="relative">
        <div
          v-if="!disabled"
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3"
        >
          <span
            :class="[
              disabled ? 'text-gray-300' : 'text-gray-500',
              'sm:text-sm'
            ]"
          >
            {{ currency }}
          </span>
        </div>
        <input
          v-if="!disabled"
          :id="`${name}_input`"
          ref="priceInput"
          class="block w-full border-0 p-0 pr-3 text-right text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
          :class="[disabled ? 'cursor-not-allowed text-slate-300' : '', '']"
          :disabled="disabled"
          :max="max"
          :min="min"
          :name="name"
          :type="type"
          :value="modelValue"
          aria-describedby="price-currency"
          @change="updateChangeInput"
          @keydown="isNumber"
          @keyup="updateKeyUpInput"
        />
        <div
          v-else
          class="block w-full cursor-not-allowed border-0 p-0 text-right text-gray-500 placeholder-gray-500 focus:ring-0 sm:text-sm"
        >
          {{ $h.formatCurrency(modelValue) }}
        </div>
      </div>
      <ErrorContainer :error-key="name" :errors="errors" />
    </div>
  </template>
  <div v-else>
    <BaseShowLabel v-if="label" :display-only-label="true" :label="label" />
    {{ displayedValueWhenNotEditionMode }}
  </div>
</template>

<script>
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'BaseShowEditPriceInput',
  components: { ErrorContainer, BaseShowLabel },
  props: {
    priceType: {
      type: String,
      required: false,
      default: null
    },
    currency: {
      type: String,
      required: false,
      default: '€'
    },
    name: {
      type: String,
      default: ''
    },
    editionMode: {
      type: Boolean,
      default: true
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '',
      require: true
    },
    modelValue: {
      type: [String, Number],
      default: '',
      require: true
    },
    type: {
      type: String,
      required: false,
      default: 'number'
    },
    errors: {
      type: Array,
      default() {
        return []
      }
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    displayPriceType: {
      type: Boolean,
      required: false,
      default: true
    },
    placeholder: {
      type: [String, Number],
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    },
    parseType: {
      type: String,
      required: false,
      default: 'float',
      validator: function (value) {
        const admittedTypes = ['text', 'int', 'float']
        return admittedTypes.indexOf(value) !== -1
      }
    }
  },
  emits: ['update:modelValue', 'update:modelValue:keyUp'],
  data() {
    return {
      isHighlighted: false
    }
  },
  computed: {
    displayedValueWhenNotEditionMode() {
      if (this.modelValue) {
        if (this.displayPriceType) {
          return !this.priceType
            ? `${this.modelValue} ${this.currency}`
            : `${this.modelValue} ${this.currency} (${this.priceType})`
        } else {
          return !this.priceType
            ? `${this.modelValue} ${this.currency}`
            : `${this.modelValue} ${this.currency}`
        }
      }
      return !this.priceType
        ? `-  ${this.currency}`
        : `-  ${this.currency} (${this.priceType})`
    }
  },
  methods: {
    restrictedRangeNumberValue(payload) {
      if (this.min || this.max) {
        if (this.payload < this.min) {
          return this.min
        }
        if (this.payload > this.max) {
          return this.max
        }
        return payload
      }
      return payload
    },
    isNumber(event) {
      if (
        [109, 189].includes(event.keyCode) &&
        event.target.value.toString().length &&
        event.target.value.toString().startsWith('-')
      ) {
        return event.preventDefault()
      }
      if (this.getNumberOfDigitsAfterTheComa(event.target.value) >= 2) {
        if (![8, 9, 13, 16, 37, 38, 39, 40].includes(event.keyCode)) {
          if (!this.isHighlighted) {
            this.$refs.priceInput.select()
            this.isHighlighted = true
          } else {
            return this.$emit('update:modelValue', null)
          }
        }
      }
      if (this.parseType === 'float') {
        if (
          !/\d/.test(event.key) &&
          ![8, 9, 37, 38, 39, 40, 188, 190, 109, 189].includes(event.keyCode)
        ) {
          return event.preventDefault()
        }
      }
      if (this.parseType === 'int') {
        if (
          !/\d/.test(event.key) &&
          ![8, 9, 37, 38, 39, 40, 108, 190, 109, 189].includes(event.keyCode)
        ) {
          return event.preventDefault()
        }
      }
    },
    updateKeyUpInput(event) {
      return this.updateInput(event, 'update:modelValue:keyUp')
    },
    updateChangeInput(event) {
      return this.updateInput(event, 'update:modelValue')
    },
    updateInput(event, eventName) {
      switch (this.parseType) {
        case 'int':
          return this.$emit(
            eventName,
            event.target.value !== '' && !isNaN(event.target.value)
              ? this.restrictedRangeNumberValue(parseInt(event.target.value))
              : ''
          )
        case 'float':
          return this.$emit(
            eventName,
            event.target.value !== '' && !isNaN(event.target.value)
              ? this.restrictedRangeNumberValue(parseFloat(event.target.value))
              : ''
          )
        default:
          return this.$emit(
            eventName,
            this.max && parseInt(this.max) < event.target.value
              ? parseInt(this.max)
              : event.target.value
          )
      }
    },
    getNumberOfDigitsAfterTheComa(payload) {
      let temp = payload.toString().split('.')
      if (temp.length && temp.length > 1) {
        return temp[1].length
      }
      return null
    }
  }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
