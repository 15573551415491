<template>
  <div v-if="locationsByFuneralHomeCompany[supplierOrder.funeral_home_id]?.length < 1" class="ml-3 text-gray-500 font-medium">Pas d'emplacement disponible pour la societé de cette agence.</div>
  <div v-else>
    <label
      :for="name"
      class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
    >Emplacement de livraison</label>
    <div
      class="border border-gray-200 rounded-lg"
    >
      <div
        v-for="(site, count_site) in locationsByFuneralHomeCompany[supplierOrder.funeral_home_id]"
        :key="count_site"
        class="mx-4 my-4"
      >
        <span class="font-bold ml-2">{{ site.name }}</span>
        <ul
          class="text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          <li
            v-for="(location, count_location) in site.locations"
            :key="count_location"
            class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
            <div class="flex items-center ps-3">
              <input
                :id="location.id" v-model="selectedLocation" :value="location.id" type="radio"
                :name="location.id" :checked="location.id === supplierOrder.delivery_place_id"
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              <label
                :for="location.id"
                class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                  location.name
                }}</label>
            </div>
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex"

export default {
  name: 'DeliveryPlaceListLocations',
  props: {
    supplierOrder: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedLocation: null
    }
  },
  computed: {
    ...mapGetters({
      funeralHomes: 'metadata/getFuneralHomesMetadata',
      sites: 'metadata/getSiteMetadata',
      locations: 'metadata/getLocationsMetadata'
    }),
    funeralHome() {
      if(!this.supplierOrder.funeral_home_id) return null
      return this.funeralHomes.find(fh => fh.id === this.supplierOrder.funeral_home_id)
    },
    locationsByFuneralHomeCompany() {
      if (!this.funeralHome) return {};

      const relatedFuneralHomes = this.funeralHomes.filter(fh => fh.company_id === this.funeralHome.company_id);

      const relatedSites = this.sites.filter(site =>
        relatedFuneralHomes.some(fh => fh.id === site.funeral_home_id)
      );

      const result = relatedSites.map(site => ({
        id: site.id,
        name: site.name,
        locations: this.locations
          .filter(location => location.site_id === site.id && location.is_storage_location)
          .map(location => ({
            id: location.id,
            name: `${location.name} (${site.name})`
          }))
      }));

      return {
        [this.funeralHome.id]: result
      };
    }
  },
  watch: {
    selectedLocation(newValue, oldValue) {
      if (newValue !== oldValue) {
          this.supplierOrder.delivery_place_type = 'SFU'
          this.supplierOrder.company_id = this.funeralHome.company_id
          this.supplierOrder.delivery_place_id = this.selectedLocation
      }
    }
  },
}
</script>
