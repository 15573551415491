<template>
  <BaseSlideOverModal v-model:is-modal-open="localOpen" width="2xl">
    <template #body>
      <div v-if="invoiceable" class="flex h-full min-h-0 w-full flex-col">
        <div class="flex-shrink-0">
          <div
            class="flex flex-col rounded-t bg-gradient-to-r from-primary-300 to-primary-500 p-4 text-white"
          >
            <div class="flex">
              <div class="flex flex-1 flex-col">
                <span class="text-2xl leading-none capitalize-first">{{
                  invoiceable?.civility
                }}</span>
                <span
                  v-if="invoiceable?.full_name"
                  class="text-4xl font-bold capitalize"
                  >{{ invoiceable?.full_name }}</span
                >
                <span
                  v-else-if="invoiceable?.name"
                  class="text-4xl font-bold capitalize"
                  >{{ invoiceable?.name }}</span
                >
                <span class="text-xl">{{ invoiceable?.death_date }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2 flex-1 overflow-y-scroll px-4 py-5 sm:px-0 sm:py-0">
          <dl
            class="space-y-3 rounded-md dark:border dark:border-white sm:space-y-0 sm:divide-y sm:divide-gray-200"
          >
            <div v-if="invoiceable?.street" class="sm:flex sm:px-6 sm:py-5">
              <dt
                class="text-sm font-medium text-gray-500 capitalize-first dark:text-gray-400 sm:w-40 sm:flex-shrink-0 lg:w-48"
              >
                {{ $t('attributes.address') }}
              </dt>
              <dd
                class="mt-1 text-sm text-gray-900 dark:text-white sm:col-span-2 sm:ml-6 sm:mt-0"
              >
                {{ invoiceable?.street }}<br />{{ invoiceable?.zip }}
                {{ invoiceable?.city }}
              </dd>
            </div>
            <div v-if="invoiceable?.birth_date" class="sm:flex sm:px-6 sm:py-5">
              <dt
                class="text-sm font-medium text-gray-500 capitalize-first dark:text-gray-400 sm:w-40 sm:flex-shrink-0 lg:w-48"
              >
                {{ $t('attributes.birth_date') }}
              </dt>
              <dd
                class="mt-1 text-sm text-gray-900 dark:text-white sm:col-span-2 sm:ml-6 sm:mt-0"
              >
                {{ invoiceable?.birth_date }}
              </dd>
            </div>
            <div
              v-if="invoiceable?.birth_place"
              class="sm:flex sm:px-6 sm:py-5"
            >
              <dt
                class="text-sm font-medium text-gray-500 capitalize-first dark:text-gray-400 sm:w-40 sm:flex-shrink-0 lg:w-48"
              >
                {{ $t('attributes.birth_place') }}
              </dt>
              <dd
                class="mt-1 text-sm text-gray-900 dark:text-white sm:col-span-2 sm:ml-6 sm:mt-0"
              >
                {{ invoiceable?.birth_place }}
              </dd>
            </div>
          </dl>
          <div
            v-if="invoiceable?.dossiers"
            class="mt-2 flex flex-col gap-2 px-0.5"
          >
            <h4
              class="text-xl font-bold text-gray-700 capitalize-first dark:text-white sm:text-xl"
            >
              {{ $t('global.linked_dossiers') }}
            </h4>
            <ShowInvoiceableModalTable
              v-if="invoiceable?.dossiers"
              :person="invoiceable"
              :dossiers="invoiceable?.dossiers"
            />
          </div>
          <div v-else class="px-6">
            <h4
              class="py-3 text-xl font-bold text-gray-500 dark:text-white sm:text-2xl"
            >
              {{ $t('global.no_linked_dossiers') }}
            </h4>
          </div>
        </div>
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import { mapActions } from 'vuex'
import BaseSlideOverModal from '@c/addf-package/components/modal/BaseSlideOverModal'
import ShowInvoiceableModalTable from './ShowInvoiceableModalTable.vue'

export default {
  name: 'ShowInvoiceableModal',
  components: {
    ShowInvoiceableModalTable,
    BaseSlideOverModal
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    invoiceableId: {
      type: [String, Number],
      required: true
    },
    invoiceableType: {
      type: String,
      required: false,
      default: 'App\\Models\\Person'
    }
  },
  emits: ['selectedInvoicebale', 'update:open'],
  data() {
    return {
      invoiceable: null
    }
  },
  computed: {
    localOpen: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      }
    }
  },
  watch: {
    open(newValue, _oldValue) {
      if (newValue) {
        this.processFetchInvoiceableByIdAction({
          invoiceable_id: this.invoiceableId,
          invoiceable_type: this.invoiceableType
        }).then((response) => (this.invoiceable = response.invoiceable))
      }
    }
  },
  methods: {
    ...mapActions({
      processFetchInvoiceableByIdAction: 'dossier/fetchInvoiceableById'
    }),
    handleSelectPerson() {
      this.$emit('selectedInvoicebale', this.invoiceable.id)
      this.$emit('update:open', false)
    }
  }
}
</script>
