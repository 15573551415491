<template>
  <div v-if="editionMode">
    <div
      class="flex flex-row divide-x divide-slate-300 rounded-md border border-slate-300 bg-white shadow-sm outline-none focus-within:border-primary-300 focus-within:ring-0 dark:divide-opacity-0"
      :class="labelDate ? 'mt-2' : ''"
    >
      <div class="relative w-2/3 dark:bg-slate-700 dark:text-white">
        <label
          v-if="labelDate"
          :for="name"
          class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
          >{{ labelDate }}
          <span v-if="labelDate && required" class="text-red-600">*</span>
        </label>
        <div
          class="rounded-md px-3 py-2 dark:border dark:border-white dark:bg-slate-500"
        >
          <DatePicker
            v-model:date="dateValue"
            :view="view"
            :events="events"
            :columns="columns"
            :min-date="minDate"
            :max-date="maxDate"
          />
        </div>
        <button
          v-if="!required && date"
          type="button"
          tabindex="-1"
          class="absolute inset-y-0 right-0 mr-3 flex items-center pl-3 text-red-600"
          @click.prevent="() => resetDate()"
        >
          <FontAwesomeIcon :icon="['fal', 'times']" />
        </button>
      </div>
      <div class="relative w-1/3 pr-1 dark:bg-slate-700 dark:text-white">
        <label
          v-if="labelTime"
          class="absolute -top-2 left-2 -mt-px inline-block rounded-md bg-white px-1 text-xs font-medium text-gray-900 capitalize-first"
          >{{ labelTime }}
          <span v-if="labelTime && required" class="text-red-600">*</span>
        </label>
        <input
          v-model="timeValue"
          v-mask="'##:##'"
          :placeholder="!labelTime || labelTime.length === 0 ? '##:##' : ''"
          type="text"
          class="block w-full border-0 p-0 px-3 py-2 text-gray-900 placeholder-gray-500 focus:ring-0 dark:rounded-md dark:border dark:border-white dark:bg-slate-500 dark:text-white sm:text-sm"
        />
        <span
          v-if="alertEmptyHour && dateValue && timeValue.length === 0"
          class="absolute inset-y-0 right-0 mr-3 flex items-center text-orange-400"
        >
          <FontAwesomeIcon :icon="['fas', 'exclamation-triangle']" />
        </span>
        <button
          v-if="!required && timeValue !== ''"
          type="button"
          tabindex="-1"
          class="absolute inset-y-0 right-0 mr-3 flex items-center pl-3 text-red-600"
          @click.prevent="() => resetHour()"
        >
          <FontAwesomeIcon :icon="['fal', 'times']" />
        </button>
      </div>
    </div>
    <ErrorContainer :error-key="name" :errors="errors" />
  </div>
  <template v-else>
    <BaseShowLabel
      :label="labelDate"
      :model-value="cDisplayedValueWhenNotEditionMode"
    />
  </template>
</template>

<script>
import moment from 'moment'
import DatePicker from '@c/addf-package/components/BaseShowEditDatePicker/Component/DatePicker.vue'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseShowEditDateTimePickerv2',
  components: { FontAwesomeIcon, ErrorContainer, BaseShowLabel, DatePicker },
  props: {
    date: {
      type: String,
      required: true
    },
    dateTo: {
      type: String,
      required: false,
      default: null
    },
    timeUndefined: {
      type: Boolean,
      required: true
    },
    labelDate: {
      type: String,
      required: true
    },
    labelTime: {
      type: String,
      required: false,
      default: null
    },
    minDate: {
      type: String,
      required: false,
      default: null
    },
    maxDate: {
      type: String,
      required: false,
      default: null
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    view: {
      type: String,
      required: false,
      default: 'monthly'
    },
    events: {
      type: Array,
      required: false,
      default: null
    },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    alertEmptyHour: {
      type: Boolean,
      required: false,
      default: true
    },
    columns: {
      type: Number,
      required: false,
      default: 1
    },
    name: {
      type: String,
      required: false,
      default: 'date_from'
    },
    defaultDuration: {
      type: Number,
      required: false,
      default: 60
    }
  },
  emits: ['update:timeUndefined', 'update:date', 'update:dateTo'],
  computed: {
    dateValue: {
      get() {
        if (this.isDateValid()) {
          let _moment = moment(this.date)
          if (_moment.isValid()) {
            return _moment.toDate()
          }
        }
        return null
      },
      set(value) {
        const isDefine = this.isDefine(value)
        if (!isDefine) {
          this.$emit('update:date', null)
          this.$emit('update:timeUndefined', true)
        } else {
          let _moment = moment(value)
          if (isDefine && _moment.isValid()) {
            this.$emit('update:date', _moment.format('YYYY-MM-DD HH:mm'))
          } else {
            this.$emit('update:date', null)
            this.$emit('update:timeUndefined', true)
          }
        }
      }
    },
    timeValue: {
      get() {
        if (!this.timeUndefined && this.isDateValid()) {
          let _moment = moment(this.date)
          if (_moment.isValid()) {
            return _moment.format('HH:mm')
          }
        }
        return ''
      },
      set(value) {
        if (value.length === 5) {
          if (value.match(/^([01][0-9]|2[0-3]):([0-5][0-9])$/)) {
            let time = moment(value, 'HH:mm')
            let date = moment(this.date).set({
              hour: time.get('hour'),
              minute: time.get('minute'),
              second: 0
            })
            this.$emit('update:timeUndefined', false)
            this.$emit('update:date', date.format('YYYY-MM-DD HH:mm'))
          } else {
            this.$emit('update:timeUndefined', true)
          }
        } else if (!value || !value.length) {
          let date = moment(this.date).set({
            hour: 0,
            minute: 0,
            second: 0
          })
          this.$emit('update:timeUndefined', true)
          this.$emit('update:date', date.format('YYYY-MM-DD HH:mm'))
        }
      }
    },
    cDisplayedValueWhenNotEditionMode() {
      let _moment = moment(this.dateValue)
      if (_moment.isValid()) {
        return _moment.format(
          this.timeUndefined ? 'DD/MM/YYYY' : 'DD/MM/YYYY : HH:mm'
        )
      }
      return ''
    }
  },
  watch: {
    date: {
      handler(newValue, oldValue) {
        // calcul du nouveau date_to en appliquant l'ancien offset
        if (
          oldValue !== undefined && // test si ce n'est pas le watch au mount
          newValue !== oldValue && // si va leur n'a pas changé, ne rien faire
          this.dateTo !== undefined // test si le dateTo a bien été envoyé dans le v-model
        ) {
          if (this.dateTo === null) {
            this.$emit(
              'update:dateTo',
              // on applique l'offset de temps sur le nouveau dateFrom pour avoir le nouveau dateTo
              moment(newValue)
                .add(60 * this.defaultDuration, 'seconds')
                .format('YYYY-MM-DD HH:mm')
            )
          } else {
            // récupération de la différence de temps entre le dateFrom original et le date to
            let offset = moment(this.dateTo).diff(moment(oldValue), 'seconds')
            if (offset > 0) {
              this.$emit(
                'update:dateTo',
                // on applique l'offset de temps sur le nouveau dateFrom pour avoir le nouveau dateTo
                moment(newValue)
                  .add(offset, 'seconds')
                  .format('YYYY-MM-DD HH:mm')
              )
            } else {
              this.$emit(
                'update:dateTo',
                // on applique l'offset de temps sur le nouveau dateFrom pour avoir le nouveau dateTo
                moment(newValue)
                  .add(60 * this.defaultDuration, 'seconds')
                  .format('YYYY-MM-DD HH:mm')
              )
            }
          }
        }
      }
    }
  },
  mounted() {},
  methods: {
    resetHour() {
      this.timeValue = ''
    },
    resetDate() {
      this.dateValue = null
    },
    isDateValid() {
      return this.date !== null && this.date !== undefined
    },
    isDefine(value) {
      return value !== null && value !== undefined
    }
  }
}
</script>
