<template>
  <div>
    <div
      class="fm-form-input"
      :class="{
        'mt-2': !!label,
        'bg-white': !disabled,
        'bg-gray-100': disabled
      }"
    >
      <label v-if="label">
        {{ label }}
        <span v-if="label && required" class="text-red-600">*</span>
      </label>
      <Combobox v-model="localModelValue" :disabled="disabled">
        <Float
          adaptive-width
          :auto-placement="{
            autoAlignment: true,
            allowedPlacements: ['bottom', 'top']
          }"
          :offset="1"
          @hide="query = ''"
        >
          <div
            class="w-full cursor-default overflow-hidden rounded-lg bg-white text-left"
          >
            <ComboboxInput
              autocomplete="nope"
              autofill="nope"
              class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              :display-value="
                (item) =>
                  values.find((option) => option[trackBy] === item)?.[
                    displayKey
                  ]
              "
              :placeholder="selectedValuesLabel"
              @change="query = $event.target.value"
            />
            <div class="absolute inset-y-0 right-2 flex items-center gap-2">
              <ComboboxButton>
                <FontAwesomeIcon :icon="['fal', 'chevron-down']" />
              </ComboboxButton>
              <button
                v-if="!required && localModelValue"
                type="button"
                tabindex="-1"
                class="flex-shrink-0 text-red-600"
                @click.prevent="$emit('update:modelValue', null)"
              >
                <FontAwesomeIcon :icon="['far', 'times']" />
              </button>
            </div>
          </div>
          <ComboboxOptions
            class="max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-white dark:bg-slate-500 dark:text-gray-300 sm:text-sm"
          >
            <div
              v-if="filteredOptions.length === 0 && query !== ''"
              class="relative cursor-default select-none px-4 py-2 text-gray-700"
            >
              Nothing found.
            </div>

            <ComboboxOption
              v-for="item in filteredOptions"
              :key="item[trackBy]"
              v-slot="{ selected, active, disabled }"
              as="template"
              :value="item[trackBy]"
            >
              <li
                class="relative cursor-default select-none py-2 pl-8 pr-4"
                :class="{
                  'bg-gray-200 text-gray-700': disabled,
                  'bg-primary-500 text-contrast-primary': selected,
                  'bg-primary-300 text-white': active,
                  'text-gray-900': !active && !selected
                }"
              >
                <slot
                  name="option"
                  :option="item"
                  :active="active"
                  :selected="selected"
                >
                  <span
                    class="flex w-full flex-row items-center gap-1.5 truncate"
                  >
                    <span v-if="colored" class="shrink-0">
                      <span
                        :data-tint="item.bg_color"
                        class="block h-3 w-3 rounded-full bg-primary"
                      ></span>
                    </span>
                    <span
                      class="flex-1"
                      :class="[
                        selected ? 'font-medium' : 'font-normal',
                        capitalize ? 'capitalize-first' : ''
                      ]"
                    >
                      {{ item[displayKey] }}
                    </span>
                  </span>
                </slot>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3"
                >
                  <FontAwesomeIcon :icon="['fas', 'check']" />
                </span>
                <span
                  v-if="item.favorite"
                  class="absolute inset-y-0 right-0 flex items-center pr-4 text-yellow-400"
                >
                  <FontAwesomeIcon :icon="['fas', 'star-of-life']" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </Float>
      </Combobox>
    </div>
    <ErrorContainer :error-key="name" :errors="errors" />
  </div>
</template>

<script>
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Float } from '@headlessui-float/vue'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'SelectSimpleSearchableComponent',
  components: {
    ErrorContainer,
    Float,
    ComboboxOption,
    ComboboxOptions,
    FontAwesomeIcon,
    ComboboxButton,
    ComboboxInput,
    Combobox
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'normal'
    },
    values: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    displayKey: {
      type: String,
      required: false,
      default: 'name'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    selectedLabel: {
      type: String,
      required: false,
      default: null
    },
    noneSelectedLabel: {
      type: String,
      required: false,
      default: null
    },
    numberDisplayed: {
      type: Number,
      required: false,
      default: 1
    },
    includeSelectAllOption: {
      type: Boolean,
      required: false,
      default: true
    },
    favorite: {
      type: Array,
      required: false,
      default: null
    },
    favoriteOnTop: {
      type: Boolean,
      required: false,
      default: true
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    colored: {
      type: Boolean,
      required: false,
      default: false
    },
    capitalize: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      query: ''
    }
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    selectedValuesLabel() {
      if (!this.selectedValues) {
        return this.noneSelectedLabel ?? this.$t('global.aucun_selectionne')
      }
      return ''
    },
    filteredOptions() {
      if (this.query.length === 0) {
        if (
          this.values.length > 20 &&
          Array.isArray(this.favorite) &&
          this.favorite.length > 0
        ) {
          return this.favoriteValues
        } else {
          return this.orderedValues
        }
      } else {
        return this.orderedValues.filter((value) => {
          return value[this.displayKey]
            .toLowerCase()
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .includes(this.query.toLowerCase())
        })
      }
    },
    favoriteValues() {
      if (!Array.isArray(this.favorite) || this.favorite.length === 0) {
        return []
      }
      return this.values
        .filter((value) => this.favorite.includes(value[this.trackBy]))
        .map((value) => {
          return {
            ...value,
            favorite: true
          }
        })
    },
    otherValues() {
      if (!Array.isArray(this.favorite) || this.favorite.length === 0) {
        return this.values
      }
      return this.values.filter(
        (value) => !this.favorite.includes(value[this.trackBy])
      )
    },
    orderedValues() {
      return [...this.favoriteValues, ...this.otherValues]
    }
  }
}
</script>

<style scoped></style>
