<template>
  <div
    class="text-sm font-medium text-gray-900 capitalize-first"
    :class="$can('manage_settings') ? '' : 'cursor-not-allowed'"
  >
    {{ $t(translation + nameTranslate) }}
    <input
      class="ml-6 h-4 w-4 rounded bg-gray-100 text-blue-600"
      type="checkbox"
      :id="name"
      :disabled="!$can('manage_settings')"
      v-model="model"
    />
  </div>
  <div>
    <p
      v-if="remarks"
      class="mt-3 text-xs leading-6 text-gray-600"
      v-html="remarks"
    ></p>
  </div>
</template>
<script>
import BaseSettingsMixin from '@/views/settings/general/baseSettingsComponents/mixin/BaseSettingsMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'BaseSettingsSimpleCheck',
  mixins: [BaseSettingsMixin],
  props: {
    defaultValue: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    remarks: {
      type: [String, null],
      required: false,
      default: null
    },
    funeralHomeId: {
      type: [String, null],
      required: false,
      default: null
    },
    updateOtherSetting: {
      type: Boolean,
      required: false,
      default: false
    },
    settingNameToUpdate: {
      type: Boolean,
      required: false,
      default: false
    },
    translation: {
      type: String,
      required: false,
      default: 'enums.settings.settingsKeys.'
    },
    translationName: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    ...mapGetters({ initialMetadata: 'metadata/getInitialMetadata' }),
    nameTranslate() {
      return this.translationName ?? this.name
    },
    model: {
      get() {
        return this.defaultValue
      },
      set(value) {
        console.log(this.name, value)
        let payload = {
          name: this.name,
          value: value,
          successCallback: () =>
            this.processSetSingleSettingMutation({
              funeralHomeId: this.funeralHomeId,
              setting: this.name,
              values: value
            })
        }

        this.changeSettingsKeys(payload)

        if (this.updateOtherSetting && value) {
          if (this.initialMetadata.settings[this.settingNameToUpdate] === 0) {
            let payload = {
              name: this.settingNameToUpdate,
              value: 1,
              successCallback: () =>
                this.processSetSingleSettingMutation({
                  funeralHomeId: this.funeralHomeId,
                  setting: this.settingNameToUpdate,
                  values: 1
                })
            }
            this.changeSettingsKeys(payload)
          }
        } else if (this.updateOtherSetting && !value) {
          let payload = {
            name: this.settingNameToUpdate,
            value: 0,
            successCallback: () =>
              this.processSetSingleSettingMutation({
                funeralHomeId: this.funeralHomeId,
                setting: this.settingNameToUpdate,
                values: 0
              })
          }
          this.changeSettingsKeys(payload)
        }
      }
    }
  }
}
</script>
