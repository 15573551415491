<template>
  <div
    class="flex flex-col rounded border p-2 dark:border dark:border-white dark:bg-slate-700"
    :class="[selected ? 'border-primary' : '']"
  >
    <div class="flex flex-row">
      <h4 class="flex-1 text-base font-semibold">{{ person.full_name }}</h4>
      <div>
        <slot name="cta" />
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'PersonCard',
  props: {
    person: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
