<template>
  <BaseSlideOverModal v-model:is-modal-open="localOpen" width="2xl">
    <template #cta>
      <BaseButton
        v-if="current_view === 'view'"
        class="dark:border dark:border-primary-400 dark:bg-slate-700 dark:text-primary-300 dark:hover:bg-primary-600"
        :title="$t('button.edit')"
        icon="edit"
        @click="() => toggleView('edit')"
      />
      <BaseButton
        v-if="current_view === 'edit'"
        theme-color="bg-secondary text-contrast-seconday"
        class="dark:border dark:border-secondary-400 dark:bg-slate-700 dark:text-secondary-300 dark:hover:bg-secondary-600"
        :title="$t('button.cancel')"
        icon="times"
        @click="() => toggleView('edit')"
      />
      <BaseButton
        v-if="current_view === 'edit'"
        class="dark:border dark:border-primary-400 dark:bg-slate-700 dark:text-primary-300 dark:hover:bg-primary-600"
        :title="$t('button.edit')"
        icon="edit"
        @click="savePerson"
      />
    </template>
    <template #body>
      <div v-if="person" class="flex h-full min-h-0 w-full flex-col">
        <div class="flex-shrink-0">
          <div
            class="flex flex-col rounded-t bg-gradient-to-r from-primary-300 to-primary-500 p-4 text-white dark:rounded-md"
          >
            <div class="flex">
              <div v-if="person.is_company" class="flex flex-1 flex-col">
                <span class="text-2xl leading-none capitalize-first">
                  {{ person.company_name }}
                </span>
              </div>
              <div class="flex flex-1 flex-col">
                <span class="text-xl leading-none capitalize-first">
                  {{ person.civility }}
                </span>
                <span class="text-2xl font-bold capitalize">
                  {{ person.first_name }}
                  {{ person.last_name }}
                </span>
                <span>
                  {{ person.death_date }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1 overflow-y-scroll px-4 py-5 sm:px-0 sm:py-0">
          <ShowPersonModalView
            v-if="current_view === 'view'"
            :person="person"
          />
          <ShowPersonModalEdit v-else v-model:person="person" />
        </div>
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import { mapActions } from 'vuex'
import BaseSlideOverModal from '@c/addf-package/components/modal/BaseSlideOverModal'
import ShowPersonModalView from '@c/Person/component/ShowPersonModalView.vue'
import ShowPersonModalEdit from '@c/Person/component/ShowPersonModalEdit.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'ShowPersonModal',
  components: {
    BaseButton,
    ShowPersonModalEdit,
    ShowPersonModalView,
    BaseSlideOverModal
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    personId: {
      type: [String, Number],
      required: true
    }
  },
  emits: ['selectedPerson', 'update:open', 'person-updated'],
  data() {
    return {
      person: null,
      current_view: 'view'
    }
  },
  computed: {
    localOpen: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      }
    }
  },
  watch: {
    open(newValue, _oldValue) {
      if (newValue) {
        this.current_view = 'view'
        this.processFetchPersonByIdAction({ person_id: this.personId }).then(
          (response) => (this.person = response.person)
        )
      }
    }
  },
  methods: {
    ...mapActions({
      processFetchPersonByIdAction: 'dossier/FetchPersonById',
      processUpdatePersonAction: 'dossier/updatePerson'
    }),
    toggleView(view) {
      this.current_view = view
    },
    savePerson() {
      this.processUpdatePersonAction({
        person: this.person,
        person_id: this.person.id
      }).then((response) => {
        this.localOpen = false
        this.$emit('person-updated')
      })
    }
  }
}
</script>
