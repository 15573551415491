<template>
  <BaseShowEditSelectSimpleColored
      v-model="internalValue"
      :edition-mode="editionMode"
      name="status"
      :options="internalOptions"
      :searchable="true"
  />
</template>

<script>
import {mapGetters} from "vuex";
import BaseShowEditSelectSimpleColored
  from "@c/addf-package/components/BaseShowEditSelect/BaseShowEditFormSelectSimple/BaseShowEditSelectSimpleColored";

export default {
  name: "BaseShowEditInvoiceStatusInput",
  components: {BaseShowEditSelectSimpleColored},
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    invoiceDocType: {
      type: String,
      required: true
    },
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    ...mapGetters({initialMetadata: 'metadata/getInitialMetadata'}),
    internalValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('change', newValue)
      }
    },
    internalOptions (){
      console.log('status', this.initialMetadata?.enums?.invoice_status)
      return this.initialMetadata?.enums?.invoice_status
    }
  },
  mounted() {
    if(this.internalOptions?.length === 1) this.$emit('update:modelValue', this.internalOptions[0].id)
  }
}
</script>
