<template>
  <div
    v-if="contact"
    :class="[contactClass, 'rounded-md border p-5']"
    class="flex flex-col gap-2"
  >
    <!--region header-->
    <div class="flex flex-row gap-2">
      <EIDReader
        v-if="editionMode"
        :hide-title="!!full_name.length"
        @data-selected="dataFromEid"
      />
      <div class="flex-1 text-2xl font-bold">
        {{ nameFormatted }}
      </div>
      <div v-if="editionMode" class="flex-shrink-0">
        <WalkmanButton
          v-model="contact.person_type"
          :is-icon="true"
          :options="personTypeOptionForWalkman"
          size="small"
          selected-background="text-blue-300 bg-gray-100"
        />
      </div>
      <div v-if="deletable && editionMode" class="flex-shrink-0">
        <BaseButton
          color="danger"
          icon="trash"
          @click="handleRemoveContactFromContacts"
        />
      </div>
    </div>
    <!--endregion-->

    <!--region body-->
    <div :id="`contact_${contact.id}`" class="flex flex-col gap-2">
      <!--region Roles-->
      <template v-if="withRoleSection">
        <BaseCardSubSection
          v-if="canDisplayRoles"
          :line="false"
          :section-class="subSectionClass + ' flex flex-row'"
        >
          <div class="flex flex-1 flex-row">
            <div class="flex flex-1 flex-row gap-3">
              <div v-for="(role, index) in contactRoles" :key="index">
                <BaseCheckBox
                  v-model="contact[role.value]"
                  :label="
                    $t(
                      'enums.contact.contactDossierTypes.' +
                        role.name.toLowerCase()
                    )
                  "
                  :edition-mode="editionMode"
                  @change="updateContactRoles"
                />
              </div>
            </div>
            <div class="flex-shrink-0">
              <BaseCheckBox
                v-model="contact.is_company"
                name="is_company"
                :label="$t('attributes.company')"
                :edition-mode="editionMode"
              />
            </div>
          </div>
        </BaseCardSubSection>
      </template>

      <!--endregion-->

      <!--region informations -->
      <BaseCardSubSection
        :title="$t('global.informations')"
        :line="true"
        :section-class="subSectionClass"
      >
        <ContactCardInformationComponent
          :contact="contact"
          :contact-type="contact.person_type"
          :edition-mode="editionMode"
          :can-track-by-be-null="canTrackByBeNull"
          :errors="errors"
          :section="section"
          :deceased-contact-type="contactType"
        />
      </BaseCardSubSection>
      <!--endregion-->

      <div v-if="maritalStatusInfos && !contact.is_company">
        <!--region marital status-->
        <BaseCardSubSection
          :title="$t('global.wedding')"
          :section-class="subSectionClass"
        >
          <ContactCardMaritalStatusWeddingComponent
            :contact="contact"
            :edition-mode="editionMode"
          />
        </BaseCardSubSection>
        <!--endregion-->
        <!--region divorce-->
        <BaseCardSubSection
          :title="$t('global.divorce')"
          :section-class="subSectionClass"
        >
          <ContactCardDivorceComponent
            :contact="contact"
            :edition-mode="editionMode"
          />
        </BaseCardSubSection>
        <!--endregion-->
        <!--region cohabitant-->
        <BaseCardSubSection
          :title="$t('global.legal_partner')"
          :section-class="subSectionClass"
        >
          <ContactCardCohabitantLegalComponent
            :contact="contact"
            :edition-mode="editionMode"
          />
        </BaseCardSubSection>
        <!--endregion-->
      </div>

      <!--region coordinates-->
      <BaseCardSubSection
        v-if="!contact.is_deceased && allTypeExceptMinimal"
        :title="$t('global.contact_details')"
        :section-class="subSectionClass"
      >
        <ContactCardCoordonneesComponent
          :contact="contact"
          :contact-type="contactType"
          :edition-mode="editionMode"
          :errors="errors"
        />
      </BaseCardSubSection>
      <!--endregion-->

      <!--region note-->
      <BaseCardSubSection
        :title="$t('global.note')"
        :section-class="subSectionClass"
      >
        <ContactCardNoteComponent
          :contact="contact"
          :edition-mode="editionMode"
        />
      </BaseCardSubSection>
      <!--endregion-->
    </div>
    <!--endregion-->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { ContactType, Relationship } from '@/assets/enums'

import ContactCardCoordonneesComponent from '@/views/dossier/form/subpages/contact/components/components/ContactCardCoordonneesComponent'
import ContactCardDeathComponent from '@/views/dossier/form/subpages/contact/components/components/ContactCardDeathComponent'
import ContactCardNoteComponent from '@/views/dossier/form/subpages/contact/components/components/ContactCardNoteComponent'
import ContactCardInformationComponent from '@/views/dossier/form/subpages/contact/components/components/ContactCardInformationComponent'
import BaseCardSubSection from '@c/BaseCardSubSection'
import ContactCardMaritalStatusWeddingComponent from '@/views/dossier/form/subpages/contact/components/components/ContactCardMaritalStatusWeddingComponent'
import ContactCardDivorceComponent from '@/views/dossier/form/subpages/contact/components/components/ContactCardDivorceComponent'
import ContactCardCohabitantLegalComponent from '@/views/dossier/form/subpages/contact/components/components/ContactCardCohabitantLegalComponent'
import BaseCheckBox from '@c/addf-package/components/BaseCheckBox/BaseCheckBox.vue'
import WalkmanButton from '@c/WalkmanButton.vue'
import contactType from '@/assets/enums/contact/ContactType'
import ContactSection from '@/assets/enums/contact/ContactSection'
import EIDReader from '@/views/layouts/top-bar/components/EIDReader.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'TheContactCard',
  components: {
    BaseButton,
    EIDReader,
    WalkmanButton,
    BaseCheckBox,
    BaseCardSubSection,
    ContactCardCohabitantLegalComponent,
    ContactCardCoordonneesComponent,
    ContactCardDeathComponent,
    ContactCardDivorceComponent,
    ContactCardInformationComponent,
    ContactCardMaritalStatusWeddingComponent,
    ContactCardNoteComponent
  },
  props: {
    dossier: {
      type: Object,
      required: true
    },
    contact: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({})
    },
    indexContact: {
      type: Number,
      required: false,
      default: null
    },
    contactType: {
      type: String,
      required: false,
      default: 'general_contact'
    },
    canTrackByBeNull: {
      type: Boolean,
      required: false,
      default: true
    },
    withRoleSection: {
      type: Boolean,
      required: false,
      default: false
    },
    withDeathSection: {
      type: Boolean,
      required: false,
      default: false
    },
    deletable: {
      type: Boolean,
      required: false,
      default: true
    },
    isCard: {
      type: Boolean,
      required: false,
      default: true
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    section: {
      type: String,
      required: false,
      default: ContactSection.GENERAL,
      validator: (value) =>
        [
          ContactSection.ETAT_CIVIL,
          ContactSection.PARENT,
          ContactSection.ENFANT,
          ContactSection.GENERAL
        ].indexOf(value) !== -1
    }
  },
  emits: ['submit-contact'],
  data() {
    return {
      contactRoles: [
        {
          name: 'handler',
          value: 'is_handler'
        },
        {
          name: 'co_handler',
          value: 'is_co_handler'
        },
        {
          name: 'purchaser',
          value: 'is_purchaser'
        }
      ],
      contactRole: [],
      Relationship: Relationship,
      ContactType: ContactType,
      numberParents: 0
    }
  },
  computed: {
    ...mapGetters({
      metadata: 'metadata/getInitialMetadata',
      dossier: 'dossier/getDossier',
      relationshipFamilyMetadata: 'metadata/getRelationshipFamilyMetadata'
    }),
    ContactSection() {
      return ContactSection
    },
    familyRelationship() {
      return this.relationshipFamilyMetadata.map((status) => status.id)
    },
    nameFormatted() {
      if(this.section === ContactSection.PARENT ||
        this.familyRelationship?.includes(this.contact.relationship))
      {
        if(!this.full_name) {
          return 'Parent '
        }
      }

      return this.contact.is_company ? this.contact.company_name : this.full_name
    },
    full_name() {
      return [this.contact.first_name ?? null, this.contact.last_name ?? null]
        .filter((item) => item !== null && item.length > 0)
        .join(' ')
    },
    personType() {
      // return this.contactType ?? this.person.contact_type
    },
    personTypeOptionForWalkman() {
      return this.metadata?.enums?.person_types?.map((type) => {
        return {
          value: type.id,
          label: type.name,
          icon: type.icon
        }
      })
    },
    maritalStatusesRelationship() {
      return this.metadata.enums.relationship_marital_status.map(
        (status) => status.id
      )
    },
    canDisplayRoles() {
      return this.section === ContactSection.GENERAL
    },
    allTypeExceptMinimal() {
      return this.contact.person_type !== ContactType.MINIMAL
    },
    basicInfos() {
      this.minimalInfos = true
      return this.contact.person_type === contactType.BASIC
    },
    familyInfos() {
      this.minimalInfos = true
      this.basicInfos = true
      return this.contact.person_type === contactType.FAMILY
    },
    maritalStatusInfos() {
      return (
        this.contact.person_type !== ContactType.MINIMAL &&
        this.contact.person_type !== ContactType.BASIC &&
        this.contact.person_type !== ContactType.FAMILY
      )
    },
    GeneralInfos() {
      this.minimalInfos = true
      this.basicInfos = true
      this.familyInfos = true
      this.maritalStatusInfos = true
      return this.contact.person_type === contactType.GENERAL_CONTACT
    },
    canDisplayMaritalStatusComponent() {
      if (this.contact.person_type !== ContactType.MARITAL_STATUS) {
        return false
      }
      return ![
        Relationship.COHABITANT_LEGALE,
        Relationship.COHABITANTE_LEGALE
      ].includes(this.contact.relationship)
    },

    canDisplayDivorceComponent() {
      if (this.contactType !== ContactType.MARITAL_STATUS) {
        return false
      }
      return this.contact.relationship === Relationship.DIVORCE
    },

    canDisplayCohabitationComponent() {
      if (this.contactType !== ContactType.MARITAL_STATUS) {
        return false
      }
      return [
        Relationship.COHABITANT_LEGALE,
        Relationship.COHABITANTE_LEGALE
      ].includes(this.contact.relationship)
    },
    contactClass() {
      return this.contactType === ContactType.GENERAL_CONTACT
        ? this.isCard
          ? ' p-5'
          : ''
        : ''
    },
    subSectionClass() {
      return this.contactType === ContactType.GENERAL_CONTACT
        ? this.isCard
          ? ''
          : 'px-0'
        : 'px-0'
    }
  },

  beforeMount() {
    this.contactRoles.forEach((role) => {
      if (!(role.value in this.contact)) {
        this.contact[role.value] = false
      }
    })
  },
  mounted() {
    if (!this.contact.person_type) {
      this.contact.person_type = this.contactType
    }
  },
  methods: {
    ...mapMutations({
      processRemoveContactMutation: 'dossier/removeContact',
      processSetContactMutation: 'dossier/setContacts',
      processSetEditionModeMutation: 'dossier/setEditionMode'
    }),
    handleRemoveContactFromContacts() {
      this.processRemoveContactMutation(this.indexContact)
    },
    updateContactRoles() {
      if (this.contact.is_purchaser) {
        if (this.dossier.contacts.length > 1) {
          this.dossier.contacts.forEach((contact, index) => {
            if (index !== this.indexContact) {
              contact.is_purchaser = false
            }
          })
        }
      }
      if (this.contact.is_handler) {
        this.contact.is_co_handler = false
        if (this.dossier.contacts.length > 1) {
          this.dossier.contacts.forEach((contact, index) => {
            if (index !== this.indexContact) {
              contact.is_handler = false
            }
          })
        }
      }
    },
    handleSubmit() {
      this.$emit('submit-contact')
    },
    dataFromEid(data) {
      if (this.contact) {
        this.contact.first_name = data.first_name
        this.contact.last_name = data.last_name
        this.contact.gender = data.gender
        this.contact.birth_date = data.birth_date
        this.contact.birth_place = data.birth_place
        this.contact.street = data.street
        this.contact.zip = data.zip
        this.contact.city = data.city
        this.contact.nationality = data.nationality
        this.contact.national_number = data.national_number
      }
    }
  }
}
</script>
