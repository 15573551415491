<template>
  <div v-if="editionMode" :class="groupClass">
    <div>
      <!--      <p v-if="formatedCeremoniesList.length === 0"-->
      <!--         class="text-lg font-semibold leading-6 text-gray-900 capitalize-first">-->
      <!--        {{ $t('attributes.no_ceremonies_in_dossier') }}-->
      <!--      </p>-->
      <!--      <SelectComponent-->
      <!--        v-else-->
      <!--        v-model="internalValue"-->
      <!--        :values="formatedCeremoniesList"-->
      <!--        :label="label ? label : $t('attributes.ceremonies')"-->
      <!--        name="demandeur"-->
      <!--        :required="required"-->
      <!--      />-->
      <multiselect
        v-if="formatedCeremoniesList"
        v-model="internalValue"
        :options="formatedCeremoniesList"
        placeholder="select ceremonies"
        :label="attributeLabel"
        :track-by="trackBy"
        :show-labels="false"
      >
        <template #option="{ option }">
          <div class="option-item">
            <div class="option-label">{{ option.name }}</div>
            <div class="option-description text-sm text-gray-500">
              {{ option.description }}
            </div>
          </div>
        </template>
        <template #noOptions>
          <span>{{ $t('global.is_empty_list') }}</span>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseEditLabel from '@c/addf-package/components/BaseLabel/BaseEditLabel.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import moment from 'moment'
import multiselect from 'vue-multiselect'

export default {
  name: 'BaseCeremonyDossierShowEditSelectSimple',
  components: {
    multiselect
  },
  props: {
    name: {
      type: String,
      required: false,
      default: 'contact'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: String,
      required: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    dontDisplayLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    editionMode: {
      type: Boolean,
      default: true
    },
    fullModelResponse: { type: Boolean, required: false, default: false },
    dossierId: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      ceremonies: []
    }
  },
  computed: {
    formatedCeremoniesList() {
      console.log(this.ceremonies.ceremonies)
      if (
        Array.isArray(this.ceremonies.ceremonies) &&
        this.ceremonies.ceremonies.length
      ) {
        return this.ceremonies.ceremonies.map((ceremony) => {
          return {
            id: ceremony.id,
            name: ceremony.place_model?.full_name,
            description: moment(ceremony.date_from).format('DD-MM-YYYY HH:mm')
          }
        })
      }
      return []
    },
    internalValue: {
      get() {
        if (!this.modelValue) {
          return null
        }
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('change', newValue)
      }
    }
  },
  mounted() {
    if (this.$route.params?.id || this.dossierId) {
      this.processFetchCeremoniesDossierAction({
        dossier_id: this.$route.params.id || this.dossierId
      }).then((ceremonies) => (this.ceremonies = ceremonies))
    }
  },
  methods: {
    ...mapActions({
      processFetchCeremoniesDossierAction: 'dossier/fetchCeremoniesDossierList'
    })
  }
}
</script>
