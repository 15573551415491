<template>
  <BaseShowEditUndefinedRadioGroup
    v-model="contact.is_deceased"
    :label="$t('attributes.dead')"
    :edition-mode="editionMode"
    :default-value="cIsDeceased"
    :with-undefined="withUndefined"
  />
  <div
    v-if="contact.is_deceased"
    class="mt-3 grid grid-cols-1 gap-x-5 gap-y-3 md:grid-cols-2"
  >
    <div>
      <BaseShowEditDatePickerv2
        v-model:date="contact.death_date_time"
        :label="$t('attributes.death_date')"
        :today-default="false"
        :edition-mode="editionMode"
        name="death_date_time"
      />
    </div>

    <SelectCity
      v-model:city-id="contact.death_place_id"
      v-model:city-object="contact.death_place_object"
      v-model:city="contact.death_city"
      v-model:zip="contact.death_zip"
      :edition-mode="editionMode"
      :label="$t('attributes.death_place')"
      allow-tags
      @change="changePlace"
    />
  </div>
</template>

<script>
import BaseShowEditDateTimePicker from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDateTimePickerv2.vue'
import SelectCity from "@c/SelectAjaxComponent/SelectCity.vue";
import BaseShowEditUndefinedRadioGroup
  from "@c/addf-package/components/BaseRadioGroup/BaseShowEditUndefinedRadioGroup.vue";
import BaseShowEditDatePickerv2 from "@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue";

export default {
  name: 'ContactCardDeathComponent',
  components: {BaseShowEditDatePickerv2, BaseShowEditUndefinedRadioGroup, SelectCity, BaseShowEditDateTimePicker },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    contact: {
      type: Object,
      required: true
    },
    contactType: {
      type: String,
      required: true
    },
    withUndefined: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    cIsDeceased() {
      return this.contact?.is_deceased ? this.contact?.is_deceased : false
    }
  },
  methods: {
    changePlace(place) {
      console.log('place', place)
      this.contact.death_place = place
    }
  },
}
</script>
