<template>
  <BaseSlideOverModal
    v-model:is-modal-open="open"
    width="3xl"
    :title="''"
    @close="close"
  >
    <template #cta>
      <BaseButton
        :key="formButtonTitle"
        :title="formButtonTitle"
        icon="paper-plane"
        size="small"
        @click="submitForm"
      />
    </template>

    <template #body>
      <div
        class="scrollbar-custom flex h-full w-full flex-col justify-between overflow-scroll"
      >
        <SupplierOrderForm
          v-model:order-type="orderType"
          :supplier-order-id="supplierOrderId ?? selectedSupplierOrderId"
          :supplier-id="supplierId"
          :item="item"
          :need-to-fetch="needToFetch"
          :dossier="dossier"
          @address="handleAddress"
          @switch-supplier-order="handleEditSupplierOrder"
         />
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {DocTypes, InvoiceableType} from '@/assets/enums'
import moment from 'moment'
import SupplierOrderForm from '@ui/modal/supplierOrder/components/SupplierOrderForm'
import BaseButton from "@c/addf-package/components/BaseButton/BaseButton.vue";
import BaseSlideOverModal from "@c/addf-package/components/modal/BaseSlideOverModal.vue";

export default {
  name: 'SupplierOrderModal',
  components: {BaseSlideOverModal, BaseButton, SupplierOrderForm },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    supplierId: {
      type: [String, Number],
      required: false,
      default: null
    },
    item: {
      type: Object,
      required: false,
      default: null
    },
    dossier: {
      type: Object,
      required: false,
      default: null
    },
    supplierOrderId: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  emits: ['close', 'update:modelValue', 'change', 'fetch-supplier-orders'],
  data() {
    return {
      selectedSupplierOrderId: null,
      needToFetch: true,
      DocTypes: DocTypes,
      orderType: null,
      InvoiceableType: InvoiceableType
    }
  },
  computed: {
    ...mapGetters({
      supplierOrder: 'supplierOrder/getSupplierOrder',
      initialMetadata: 'metadata/getInitialMetadata',
      dossier: 'dossier/getDossier'
    }),
    formButtonTitle() {
      if (!this.supplierOrder) return null
      if (this.supplierOrder.recipient_type === InvoiceableType.SUPPLIER) {
        return this.supplierOrder.id
          ? this.$t('button.update_supplier_order')
          : this.$t('button.save_supplier_order')
      }
      if (this.supplierOrder.recipient_type === InvoiceableType.COMPANY) {
        return this.supplierOrder.id
          ? this.$t('button.update_company_order')
          : this.$t('button.save_company_order')
      }
      return 'Enregistrer une nouvelle commande'
    }
  },
  watch: {
    selectedSupplierOrderId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.needToFetch = true
      }
    }
  },
  methods: {
    ...mapActions({
      fetchSupplierByID: 'supplier/deleteSupplierById',
      processFetchNewSupplierOrderAction: 'supplierOrder/fetchNewSupplierOrder',
      processFetchDraftSupplierOrderBySupplierIdAction:
        'invoicing/fetchDraftSupplierOrderBySupplierId',
      processFetchSupplierListAction: 'supplier/fetchSupplierList',
      processStoreSupplierOrderAction: 'supplierOrder/storeSupplierOrder',
      processUpdateSupplierOrderAction: 'supplierOrder/updateSupplierOrder',
      processUpdateInvoiceItemAction: 'invoicing/updateInvoiceItem',
      processFetchAddressesByDossier: 'dossier/fetchAddressesByDossier'
    }),
    ...mapMutations({
      processSetSupplierOrderMutation: 'supplierOrder/setSupplierOrder',
      processSetInvoiceSupplierOrderAddressPropertyMutation:
        'invoicing/setInvoiceSupplierOrderAddressProperty',
      processSetInvoiceInvoiceableMutation: 'invoicing/setInvoiceInvoiceable'
    }),
    handleAddress() {
      this.needToFetch = false
      if (this.$route.name !== 'supplier_order_page_route') {
        this.processFetchAddressesByDossier({dossier_id: this.dossier?.id})
      }
    },
    handleAddressSelection(payload) {
      this.processSetInvoiceSupplierOrderAddressPropertyMutation(payload)
    },
    submitForm() {
      if (this.supplierOrder?.id) {
        this.processUpdateSupplierOrderAction({
          supplier_order_id: this.supplierOrder.id,
          supplier_order: this.supplierOrder,
        }).then(response => {
          this.$h.toastSuccess("Cette commande a bién été mise à jour")
          // this.$h.toastSuccess(this.$t('toasts.supplier_order_updated'))
          this.$emit('fetch-supplier-orders')
          this.close()
        })
      } else {

        this.supplierOrder.emission_date = moment().format()
        this.processStoreSupplierOrderAction({
          supplier_order: this.supplierOrder,
        }).then(response => {
          this.$h.toastSuccess(this.$t('toasts.supplier_order_created'))
          this.$emit('fetch-supplier-orders')
          this.close()
        })
      }
    },
    handleEditSupplierOrder(supplier_order) {
      console.log('test')
      this.processSetSupplierOrderMutation(supplier_order)
      this.selectedSupplierOrderId = supplier_order.id
    },
    handleCreateSupplierOrderButton() {
      this.needToFetch = true
      this.selectedSupplierOrderId = null
    },
    close() {
      this.selectedSupplierOrderId = null
      this.$emit('close')
    }
  }
}
</script>
