<template>
  <template v-if="editionMode && disabledWhenNotNull">
    <div class="fm-form-input" :class="label ? 'mt-2' : ''">
      <label :for="name"
        >{{ label }}&nbsp;
        <span v-if="required" class="text-red-600">*</span>
      </label>
      <div class="flex flex-row items-center rounded-md bg-gray-200">
        <div
          class="flex flex-shrink-0 items-center justify-center px-3 text-base"
        >
          <FontAwesomeIcon
            :icon="['fal', icon]"
            fixed-width="true"
            class="text-gray-600"
          />
        </div>
        <input
          :id="name"
          v-model="cModelValue"
          :name="name"
          :placeholder="internalPlaceholder"
          :disabled="editionMode"
        />
        <button
          tabindex="-1"
          type="button"
          class="flex h-8 w-10 flex-shrink-0 items-center justify-center"
          @click="isModalOpen = true"
        >
          <FontAwesomeIcon
            :icon="['fas', modelValue ? 'arrows-repeat' : 'arrow-pointer']"
          ></FontAwesomeIcon>
        </button>
      </div>
    </div>
    <ErrorContainer :error-key="name" :errors="errors" />
  </template>
  <template v-else>
    <BaseShowLabel :label="label" :model-value="cModelValue" />
  </template>
  <SelectSupplierModal
    v-model="internalValue"
    :open="isModalOpen"
    @close="handleCloseModal"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SelectSupplierModal from '@ui/modal/supplier/SelectSupplierModal'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'

export default {
  name: 'SupplierInput',
  components: { ErrorContainer, FontAwesomeIcon, SelectSupplierModal },
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: 'user-helmet-safety'
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultSupplierIdForPlaceHolder: {
      type: String,
      required: false,
      default: null
    },
    disabledWhenNotNull: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      isModalOpen: false
    }
  },
  computed: {
    ...mapGetters({
      supplierList: 'supplier/getSupplierList'
    }),
    internalPlaceholder() {
      if (!this.defaultSupplierIdForPlaceHolder) return this.label
      if (this.modelValue) return null
      return this.supplierList.find(
        (supplier) => supplier.id === this.defaultSupplierIdForPlaceHolder
      )?.name
    },
    cModelValue() {
      if (!this.supplierList) return null
      if (!this.modelValue) return null
      return this.supplierList?.find(
        (supplier) => supplier.id === this.modelValue
      )?.name
    },
    internalValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('change', newValue)
      }
    }
  },
  beforeMount() {
    if (this.supplierList.length < 1) this.processFetchSupplierListAction()
  },
  methods: {
    ...mapActions({
      processFetchSupplierListAction: 'supplier/fetchSupplierList'
    }),
    handleCloseModal() {
      this.isModalOpen = false
    }
  }
}
</script>
