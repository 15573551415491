<template>
  <div
    class="flex aspect-[6/2] cursor-pointer flex-row rounded-lg border bg-white py-2 md:flex md:px-2"
    :class="[selected ? 'border-primary-300 shadow-xl' : 'border-slate-300']"
  >
    <div class="flex flex-1 flex-col">
      <div class="flex flex-1 flex-row justify-between gap-2">
        <div class="shrink-0">
          <span
            v-if="contact.is_company"
            class="flex aspect-square w-7 flex-row items-center justify-center rounded-full bg-primary-500 text-contrast-primary"
          >
            <FontAwesomeIcon :icon="['fal', 'hotel']" />
          </span>
          <span
            v-else
            class="flex aspect-square w-7 flex-row items-center justify-center rounded-full bg-primary-500 text-contrast-primary"
          >
            <FontAwesomeIcon :icon="['fal', 'user']" />
          </span>
        </div>
        <div class="flex flex-1 flex-col">
          <div class="flex flex-row">
            <h2 class="flex-1 text-gray-800">
              {{ contactName }}
            </h2>
            <span @click.stop>
              <BaseDropDownMenu
                size="small"
                :options="menuActionsOriginal"
                :is-button="false"
              />
            </span>
          </div>

          <div v-if="contact.email" class="flex flex-row">
            <span class="text-xs text-gray-500">{{ contact.email }}</span>
          </div>
          <div v-if="contact.phone_field" class="w-full">
            <FontAwesomeIcon :icon="['fal', 'phone']" size="xs" class="mr-2" />
            <span class="text-xs text-gray-500">{{ contact.phone_field }}</span>
          </div>
          <div v-if="contact.mobile_field" class="w-full">
            <FontAwesomeIcon :icon="['fal', 'phone']" size="xs" class="mr-2" />
            <span class="text-xs text-gray-500">
              {{ contact.mobile_field }}
            </span>
          </div>
        </div>
      </div>

      <!--      commenté par aurelie: suppression pour l'instant de la liste des agences -->
      <!--      <FuneralHomeContactBadge :contact="contact" contact-class="w-10/12"/>-->
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseDropDownMenu from '@c/addf-package/components/dropdown/BaseDropDownMenu.vue'

export default {
  name: 'SelectContactCard',
  components: { BaseDropDownMenu, FontAwesomeIcon },
  // components: {FuneralHomeContactBadge},
  props: {
    contact: {
      type: Object,
      required: false,
      default: null
    },
    isWikiContact: {
      type: Boolean,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: 'user'
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['edit-contact'],
  computed: {
    contactName() {
      if (!this.isWikiContact) {
        if (this.contact?.is_company) {
          return this.contact.company_name ?? this.contact.company_name
        }

        return this.contact.full_name ?? ''
      }
      if (this.contact?.is_company) {
        return this.contact.company_name ?? this.contact.company_name
      }
      return `${this.contact.last_name ?? ''} ${this.contact.first_name ?? ''}`
    },
    menuActionsOriginal() {
      return [
        [
          {
            label: this.$t('button.edit'),
            icon: 'pen',
            gate: true,
            action: () => this.handleEditContact(this.contact)
          }
        ]
      ]
    }
  },
  methods: {
    handleEditContact(contact) {
      this.$emit('edit-contact', contact.id)
    }
  }
}
</script>
