<template>
  <div
    v-if="modelNotification"
    class="-mr-2 flex h-full w-full flex-col gap-3 pl-5 pr-2"
  >
    <div class="flex shrink-0 flex-row">
      <div class="flex-1 text-xl font-bold capitalize">
        {{ $t('attributes.compose') }}
      </div>
      <div class="flex shrink-0">
        <MailBoxCTAComponent
          v-model:model-notification="modelNotification"
          @update:model-notification="updateModelNotification"
          @on:disable:fields="v => onDisableField = v"
        />
      </div>
    </div>
    <div class="flex min-h-0 flex-1 flex-col gap-y-2 overflow-y-scroll px-1">
      <!--region To Cc Bcc-->
      <template v-if="hasFrom">
        <SelectEmailFrom
          v-model:from="modelNotification.from"
          :disabled="onDisableField"
        />
      </template>
      <div
        v-if="modelNotification.to"
        class="flex flex-row items-end gap-2"
      >
        <div class="flex-1">
          <SelectEmailable
            v-model:emailables="modelNotification.to"
            :label="$t('global.email_to')"
            :allow-tags="true"
            name="email_to"
            :errors="errors.to"
            :edition-mode="true"
            :disabled="onDisableField"
          />
        </div>
        <div v-if="!hasCC || !hasBCC" class="flex flex-shrink-0 flex-row gap-3">
          <!--#region cc buttons -->
          <div class="inline-flex rounded-md shadow-sm" role="group">
            <button
              v-if="!hasFrom"
              tabindex="-1"
              type="button"
              :class="[
                hasFrom ? 'bg-gray-200' : '',
                ccButtonClass,
                'rounded-l-lg'
              ]"
              :disabled="onDisableField"
              @click="() => (hasFrom = true)"
            >
              {{ $t('global.email_from') }}
            </button>
            <button
              v-if="!hasCC"
              tabindex="-1"
              type="button"
              :class="[hasCC ? 'bg-gray-200' : '', ccButtonClass]"
              :disabled="onDisableField"
              @click="() => (hasCC = true)"
            >
              {{ $t('global.email_cc') }}
            </button>
            <button
              v-if="!hasBCC"
              tabindex="-1"
              type="button"
              :class="[
                hasBCC ? 'bg-gray-200' : '',
                ccButtonClass,
                'rounded-r-lg'
              ]"
              :disabled="onDisableField"
              @click="() => (hasBCC = true)"
            >
              {{ $t('global.email_bcc') }}
            </button>
          </div>
          <!--#endregion -->
        </div>
      </div>

      <template v-if="hasCC || modelNotification.cc.length > 0">
        <SelectEmailable
          v-model:emailables="modelNotification.cc"
          :label="$t('global.email_cc')"
          :allow-tags="true"
          name="cc"
          :errors="errors.cc"
          :edition-mode="true"
          :disabled="onDisableField"
        />
      </template>

      <template v-if="hasBCC || modelNotification.bcc.length > 0">
        <SelectEmailable
          v-model:emailables="modelNotification.bcc"
          :label="$t('global.email_bcc')"
          :allow-tags="true"
          name="bcc"
          :errors="errors.bcc"
          :edition-mode="true"
          :disabled="onDisableField"
        />
      </template>
      <!--endregion-->

      <!--region Subject-->
      <BaseShowEditInput
        v-model="modelNotification.subject"
        :label="$t('attributes.email_subject')"
        name="subject"
        :errors="errors.subject"
        :edition-mode="true"
        :disabled="onDisableField"
      />
      <!--endregion-->

      <!--region Model-->
      <div
        v-if="
          modelNotification.model &&
          modelNotification.model_type === 'App\\Models\\Dossier'
        "
        class="mx-auto flex w-full items-center justify-between gap-x-5 rounded-md bg-primary-300 px-3 py-2 font-bold text-white"
      >
        <div class="flex">
          <FontAwesomeIcon class="mt-1" :icon="['fal', 'tag']"/>
          <p class="ml-3 capitalize-first">
            {{ modelNotification.model.title }}
          </p>
        </div>
        <div>
          <FontAwesomeIcon
            :icon="['fal', 'times']"
            class="cursor-pointer text-lg"
            @click="modelUnselected"
          />
        </div>
      </div>
      <div v-else-if="!modelNotification.model">
        <SelectDossier
          v-model:dossier="modelNotification.model"
          :disabled="onDisableField"
          @select="modelSelected"
        />
      </div>

      <!--endregion-->

      <!--region Documents dossier-->
      <BaseShowEditSelectSimple
        v-if="modelNotification.model && documentsDossier"
        :key="documentsDossier"
        v-model="dossierSelected"
        :label="$t('global.invoicing_and_attachments')"
        name="document"
        :edition-mode="true"
        :options="documentsDossier"
        :disabled="onDisableField"
        @change="getSelectedAttachment"
      />
      <!--endregion-->

      <!--region attachments list-->
      <EmailAttachmentList
        v-model:email="modelNotification"
        :document-dossier="attachment_docs"
        :errors="errors.attachments"
        :disabled="onDisableField"
        @delete-document-selected="getSelectedAttachment"
      />
      <!--endregion-->

      <!--region wysiwyg-->
      <div class="mt-3">
        <BaseWysiwyg
          v-model="modelNotification.message"
          label="message"
          :value="modelNotification.message"
          name="message"
          :errors="errors.message"
          :disabled="onDisableField"
        >
          <template #cta>
            <div class="flex flex-row gap-1.5">
              <OpenAIPopover
                :model-notification="modelNotification"
                @update-message="
                  (message) => (modelNotification.message = message)
                "
              />
              <MessageTextsPopover
                :model-notification="modelNotification"
                @update-message="
                  (message) => (modelNotification.message = message)
                "
              />
            </div>
          </template>
        </BaseWysiwyg>
        <template v-if="errors && errors.message">
          <div
            v-for="(error, index) in errors.message"
            :key="index"
            class="form-help text-red-600"
          >
            {{ $t('global.required_message') }}
          </div>
        </template>
      </div>
      <!--endregion-->
    </div>
  </div>
</template>
<script>
import EmailAttachmentList from '@ui/modal/email/components/EmailAttachmentList.vue'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import BaseWysiwyg from '@c/addf-package/components/Wysiwyg/BaseWysiwyg.vue'
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import SelectEmailable from '@c/SelectAjaxComponent/SelectEmailable.vue'
import SelectDossier from '@c/SelectAjaxComponent/SelectDossier.vue'
import MailBoxCTAComponent from '@/views/modelNotification/form/MailBoxCTAComponent.vue'
import SelectEmailFrom from '@c/SelectComponent/SelectEmailFrom.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import MessageTextsPopover from '@/views/modelNotification/form/component/MessageTextsPopover.vue'
import OpenAIPopover from '@/views/modelNotification/form/component/OpenAIPopover.vue'

export default {
  name: 'MailBoxFormComponent',
  components: {
    OpenAIPopover,
    MessageTextsPopover,
    BaseShowEditInput,
    SelectEmailFrom,
    MailBoxCTAComponent,
    SelectDossier,
    SelectEmailable,
    FontAwesomeIcon,
    BaseWysiwyg,
    EmailAttachmentList
  },
  props: {
    modelNotification: {
      type: Object,
      required: true
    }
  },
  emits: ['setResetEmailToFalse', 'update:modelNotification'],
  data() {
    return {
      checkAll: false,
      filterMessages: false,
      modelParam: null,
      hasFrom: true,
      hasCC: false,
      hasBCC: false,
      modelParams: {id: null, model: null},
      attachment_docs: [],
      documentsDossier: null,
      dossierSelected: null,
      onDisableField: false,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'auth/getErrors'
    }),
    localModelNotification: {
      get() {
        return this.modelNotification
      },
      set(value) {
        this.$emit('update:modelNotification', value)
      }
    },
    ccButtonClass() {
      return 'border border-gray-200 bg-white py-2 px-4 text-sm font-medium text-gray-900 capitalize-first'
    },
    dossierDefaultValue() {
      return this.modelNotification?.model
        ? {
          id: this.modelNotification.model_id,
          title: `${this.modelNotification?.model?.title} ${
            this.modelNotification?.model?.reference
              ? `(${this.modelNotification?.model?.reference})`
              : ''
          }`
        }
        : {}
    }
  },
  watch: {
    '$store.state.modelNotification.resetEmail': {
      handler(newVal) {
        if (newVal) {
          this.modelNotification.model = null
          this.modelNotification.to = []
          this.modelNotification.message = null
          this.modelNotification.subject = null
          this.modelNotification.message_text_id = null
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.fetchDossierDocument()
  },
  methods: {
    ...mapActions({
      processFetchNewModelNotificationAction:
        'modelNotification/fetchNewModelNotification',
      processFetchAllDocumentAttachedToDossierAction:
        'dossier/fetchAllDocumentAttachedToDossier',
      processFetchModelNotificationByIdAction:
        'modelNotification/fetchModelNotificationById'
    }),

    updateModelNotification(newItem) {
      this.$emit('update:modelNotification', newItem)
    },

    modelSelected() {
      this.modelNotification.model_type = 'App\\Models\\Dossier'
      this.modelNotification.model_id = this.modelNotification.model.id
      this.fetchDossierDocument()
    },

    modelUnselected() {
      this.modelNotification.model = null
    },

    fetchDossierDocument() {
      if (this.modelNotification.model_type === 'App\\Models\\Dossier') {
        this.processFetchAllDocumentAttachedToDossierAction({
          dossier_id: this.modelNotification?.model_id
        }).then((response) => {
          this.documentsDossier = response?.documents
        })
      }
    },

    getSelectedAttachment(value) {
      let fullValue = this.documentsDossier?.find((doc) => doc.id === value)
      if (this.attachment_docs.some((doc) => doc['id'] === value)) {
        console.log(1)
        this.attachment_docs = this.attachment_docs.filter(
          (doc) => doc.id !== value
        )
      } else {
        this.attachment_docs.push(fullValue)
      }
      this.modelNotification['attachable'] = this.attachment_docs
    }
  }
}
</script>
