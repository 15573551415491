<template>
  <div class="flex flex-col gap-6 py-3">
    <div class="grid grid-cols-2 gap-2">
      <div>
        <WalkmanButton
          v-model="localPerson.is_company"
          :label="$t('attributes.is_company')"
          :options="[
            { value: 1, label: $t('enums.yesNo.1') },
            { value: 0, label: $t('enums.yesNo.0') }
          ]"
        />
      </div>
      <template v-if="localPerson.is_company">
        <div class="col-start-1">
          <BaseShowEditInput
            v-model="localPerson.company_name"
            :label="$t('attributes.company_name')"
            name="name"
            edition-mode
            required
            :errors="errors?.company_name"
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="localPerson.tva"
            :label="$t('attributes.tva')"
            name="name"
            edition-mode
            required
            :errors="errors?.tva"
          />
        </div>
        <div class="col-start-1">
          <BaseShowEditInput
            v-model="localPerson.last_name"
            :label="$t('attributes.name')"
            name="name"
            edition-mode
            required
            :errors="errors?.last_name"
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="localPerson.first_name"
            :label="$t('attributes.first_name')"
            name="first_name"
            edition-mode
            required
            :errors="errors?.first_name"
          />
        </div>
      </template>
      <template v-else>
        <div class="col-start-1">
          <BaseShowEditRadio
            v-model="localPerson.gender"
            :label="$t('attributes.gender')"
            edition-mode
            name="gender"
            :options="metadata?.enums?.genders"
          />
        </div>
        <div class="col-start-1">
          <SelectCivility
            v-model="localPerson.civility"
            edition-mode
            :errors="errors?.civility"
          />
        </div>
        <div class="col-start-1">
          <BaseShowEditInput
            v-model="localPerson.last_name"
            :label="$t('attributes.name')"
            name="name"
            edition-mode
            required
            :errors="errors?.last_name"
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="localPerson.first_name"
            :label="$t('attributes.first_name')"
            name="first_name"
            edition-mode
            required
            :errors="errors?.first_name"
          />
        </div>
        <div v-if="localPerson.gender === Gender.FEMALE">
          <BaseShowEditInput
            v-model="localPerson.maiden_name"
            :label="$t('attributes.maiden_name')"
            name="first_name"
            edition-mode
          />
        </div>
        <div>
          <BaseShowEditInput
            v-model="localPerson.usual_name"
            :label="$t('attributes.usual_first_name')"
            :placeholder="$t('attributes.usual_first_name')"
            name="usual_first_name"
            edition-mode
          />
        </div>
      </template>
    </div>
    <div class="grid grid-cols-2 gap-2">
      <div>
        <BaseShowEditPhoneInput
          v-model:countryValue="localPerson.phone_country"
          v-model:numberValue="localPerson.phone_field"
          edition-mode
          :label="$t('attributes.phone')"
          name="phone"
          :errors="errors"
        />
      </div>
      <div>
        <BaseShowEditPhoneInput
          v-model:countryValue="localPerson.mobile_country"
          v-model:numberValue="localPerson.mobile_field"
          edition-mode
          :label="$t('attributes.mobile')"
          name="mobile"
          :errors="errors"
        />
      </div>

      <div>
        <BaseShowEditInput
          v-model="localPerson.email"
          :label="$t('attributes.email')"
          name="email"
          edition-mode
        />
      </div>
    </div>
    <div>
      <BaseShowEditAddress
        v-model:country="localPerson.country"
        v-model:city-id="localPerson.city_id"
        v-model:zip="localPerson.zip"
        v-model:city="localPerson.city"
        v-model:extra-street="localPerson.extra_street"
        v-model:street="localPerson.street"
        v-model:current-city-object="localPerson.city_object"
        class="grid-cols-2"
        edition-mode
        :errors="errors"
      />
    </div>
  </div>
</template>

<script>
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import BaseShowEditRadio from '@c/addf-package/components/BaseShowEditRadioGroup/BaseShowEditRadio.vue'
import SelectCivility from '@c/InitialStateFormComponent/Enums/SelectCivility.vue'
import SelectRelationship from '@c/InitialStateFormComponent/Enums/SelectRelationship.vue'
import { mapGetters } from 'vuex'
import Gender from '@/assets/enums/person/Gender'
import BaseShowEditAddress from '@c/BaseShowEditAddress.vue'
import BaseShowEditPhoneInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditPhoneInput.vue'
import BaseCheckBox from '@c/addf-package/components/BaseCheckBox/BaseCheckBox.vue'
import BaseBooleanRadioGroup from '@c/addf-package/components/BaseRadioGroup/BaseBooleanRadioGroup.vue'
import BaseShowEditBooleanRadioGroup from '@c/addf-package/components/BaseRadioGroup/BaseShowEditBooleanRadioGroup.vue'
import WalkmanButton from '@c/WalkmanButton.vue'

export default {
  name: 'ShowPersonModalEdit',
  components: {
    WalkmanButton,
    BaseShowEditPhoneInput,
    BaseShowEditAddress,
    SelectCivility,
    BaseShowEditRadio,
    BaseShowEditInput
  },
  props: {
    person: {
      type: Object,
      required: true
    }
  },
  emits: ['update:person'],
  data() {
    return {}
  },
  watch: {
    'localPerson.country'(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (
          !this.localPerson.phone_field ||
          this.localPerson.phone_field.length === 0
        ) {
          this.localPerson.phone_country = newValue
        }
        if (
          !this.localPerson.mobile_field ||
          this.localPerson.mobile_field.length === 0
        ) {
          this.localPerson.mobile_country = newValue
        }
      }
    }
  },
  computed: {
    Gender() {
      return Gender
    },
    ...mapGetters({
      metadata: 'metadata/getInitialMetadata',
      errors: 'auth/getErrors'
    }),
    localPerson: {
      get() {
        return this.person
      },
      set(value) {
        this.$emit('update:person', value)
      }
    }
  }
}
</script>
