<template>
  <div v-if="editionMode">
    <div class="fm-form-input" :class="label ? 'mt-2' : ''">
      <label v-if="label" :for="name"
        >{{ label }}&nbsp;
        <span v-if="label && required" class="text-red-600">*</span>
      </label>
      <div class="ibe absolute inset-y-0 left-0 flex items-center">
        <Listbox v-model="dCountryValue">
          <div class="z-0 relative h-full">
            <ListboxButton
              class="relative h-full cursor-default rounded-md border-r border-black bg-white text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 dark:rounded-r-none dark:border-r dark:border-white dark:bg-slate-500 sm:text-sm"
            >
              <span
                v-if="cGetCountryFlag"
                class="flex flex-row items-center gap-2 p-1.5 text-xs font-medium"
              >
                <img
                  :src="CountryFlags[cGetCountryFlag.country]"
                  :alt="cGetCountryFlag.country"
                  class="h-4 w-6 rounded"
                />
              </span>
              <span v-else>
                <span
                  class="align-center mx-1 flex justify-center rounded bg-gray-100 py-1 px-2"
                >
                  <FontAwesomeIcon
                    :icon="['fas', 'chevron-down']"
                    class="text-gray-500"
                  />
                </span>
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="absolute z-20 mt-1 max-h-60 w-32 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-white dark:bg-slate-500 sm:text-sm"
              >
                <div class="mb-1 px-1">
                  <input
                    v-model="searchValue"
                    type="text"
                    class="w-full rounded border p-1 text-sm focus:border-indigo-200 focus:ring-0 dark:border dark:border-primary-500 dark:bg-gray-700"
                  />
                </div>
                <ListboxOption
                  v-for="country in PhoneCountryFiltered"
                  v-slot="{ active, selected }"
                  :key="country"
                  :value="country.country"
                  as="template"
                >
                  <li
                    class="mx-0.5 flex flex-row items-center gap-2 rounded p-1.5 font-medium"
                    :class="[
                      active ? 'bg-gray-50 dark:bg-gray-300' : '',
                      selected ? 'bg-gray-200  dark:bg-gray-700' : ''
                    ]"
                  >
                    <img
                      :src="CountryFlags[country.country]"
                      :alt="country.country"
                      class="h-4 w-6 rounded"
                    />
                    <div class="flex flex-row gap-1 text-xs">
                      <span class="font-bold">{{ country.country }}</span>
                      <span class="">({{ country.prefix }})</span>
                    </div>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>
      <input
        :id="name"
        v-model="dNumberValue"
        type="text"
        :name="name + '_field'"
        class="ml-9 dark:rounded-none dark:rounded-r dark:border-none dark:bg-slate-500"
        :placeholder="placeholder"
      />
      <button
        v-if="dNumberValue && !required"
        type="button"
        class="absolute right-3 top-2"
        @click="handleDeleteValues"
      >
        <FontAwesomeIcon :icon="['fal', 'times']" class="text-red-700" />
      </button>
    </div>
    <template v-if="phoneErrors">
      <div class="form-help-container">
        <div
          v-for="(error, index) in phoneErrors"
          :key="index"
          class="form-help text-red-600"
        >
          {{ error }}
        </div>
      </div>
    </template>
  </div>
  <div v-else>
    <BaseShowPhoneLabel
      :label="label"
      :model-value="dNumberValue"
      :country-value="dCountryValue"
    />
  </div>
</template>

<script>
import * as _ from 'lodash-es'
import { mapGetters } from 'vuex'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'
import { Country } from '@/assets/enums'
import PhoneCountry from '@/assets/enums/config/PhoneCountry'
import CountryFlags from '@/assets/enums/config/CountryFlags'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseShowPhoneLabel from '@c/addf-package/components/BaseLabel/BaseShowPhoneLabel.vue'

export default {
  name: 'BaseShowEditPhoneInput',
  components: {
    BaseShowPhoneLabel,
    FontAwesomeIcon,
    ListboxOptions,
    ListboxOption,
    Listbox,
    ListboxButton
  },
  props: {
    countryValue: {
      type: String,
      required: true
    },
    numberValue: {
      type: String,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    numberPlaceholder: {
      type: String,
      required: false,
      default: ''
    },
    errors: {
      type: Object,
      required: false,
      default: () => {}
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['update:countryValue', 'update:numberValue'],
  data() {
    return {
      phoneErrors: null,
      PhoneCountry,
      CountryFlags,
      searchValue: ''
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata'
    }),
    Country() {
      return Country
    },
    cDisplayedValueWhenNotEditionMode() {
      if (this.countryValue && this.numberValue) {
        return `${this.countryValue} - ${this.numberValue}`
      }
      return '-'
    },
    cGetCountryFlag() {
      return PhoneCountry.find((item) => item.country === this.dCountryValue)
    },
    PhoneCountryFiltered() {
      if (this.searchValue.length === 0) {
        return this.PhoneCountry
      }
      return this.PhoneCountry.filter(
        (item) =>
          item.prefix.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          item.country.toLowerCase().includes(this.searchValue.toLowerCase())
      )
    },
    dNumberValue: {
      get() {
        return this.numberValue
      },
      set(value) {
        this.$emit('update:numberValue', value)
      }
    },
    dCountryValue: {
      get() {
        return this.countryValue
      },
      set(value) {
        this.$emit('update:countryValue', value)
      }
    }
  },
  watch: {
    errors: {
      handler(newValue) {
        this.phoneErrors = [
          ...(this.errors?.[`${this.name}_country`] ?? []),
          ...(this.errors?.[`${this.name}_field`] ?? [])
        ]
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.dCountryValue =
      this.dCountryValue ?? this.initialMetadata?.settings?.default_country
  },
  methods: {
    handleDeleteValues() {
      this.dNumberValue = ''
    }
  }
}
</script>
