import {
  base_order_funeral,
  base_order_remain
} from '@/assets/settings/EventsOrder'

export default {
  getUsersMetadata(state) {
    return state.initialMetadata.models.users
  },
  getUserMetadata: (state) => (id) => {
    return state.initialMetadata.models.users.find((user) => user.id === id)
  },
  getAllUserMetadata: (state) => (id) => {
    return state.initialMetadata.models.all_users.find((user) => user.id === id)
  },
  getMaterialResourceMetadata: (state) => (id) => {
    return state.initialMetadata.models.material_resources.find(
      (material_resource) => material_resource.id === id
    )
  },
  getMaterialResourcesMetadata: (state) => {
    return state.initialMetadata.models.material_resources ?? []
  },
  getCompaniesMetadata(state) {
    return state.initialMetadata.models.companies ?? []
  },
  getFuneralHomesMetadata(state) {
    return state.initialMetadata.models.funeralHomes ?? []
  },
  getSiteMetadata(state) {
    return state.initialMetadata.models.sites
  },
  getRoomsMetadata(state) {
    return state.initialMetadata.models.rooms_by_funerarium_id
  },
  getLocationsMetadata(state) {
    return state.initialMetadata.models.locations ?? []
  },
  getSuppliersMetadata(state) {
    return state.initialMetadata.models.suppliers ?? []
  },
  getFuneralHomeMetadata: (state) => (id) => {
    return state.initialMetadata.models.funeralHomes.find(
      (user) => user.id === id
    )
  },
  getDossierNumberingsMetadata: (state) => {
    return state.initialMetadata.models.dossier_numberings ?? []
  },
  getInvoicingCategoriesMetadata: (state) => {
    return state.initialMetadata.models.invoicing_categories ?? []
  },
  getInvoiceStylingTypesMetadata: (state) => {
    return state.initialMetadata.enums.invoice_styling_types ?? []
  },

  getDossierStatusMetadata(state) {
    return state.initialMetadata.enums.dossier_status ?? []
  },
  getCivilityMetadata(state) {
    return state.initialMetadata.enums.civilities ?? []
  },
  getGenderMetadata(state) {
    return state.initialMetadata.enums.genders ?? []
  },
  getTransfertTimeTypeMetadata(state) {
    return state.initialMetadata.enums.transfer_hourly_types ?? []
  },
  getMaritalStatusesMetadata(state) {
    return state.initialMetadata.enums.maritalStatuses ?? []
  },
  getLifeSituationsMetadata(state) {
    return state.initialMetadata.enums.lifeSituations ?? []
  },
  getDiplomesMetadata(state) {
    return state.initialMetadata.enums.diplomes ?? []
  },

  getRelationshipsMetadata(state) {
    return state.initialMetadata.enums.relationship ?? []
  },
  getRelationshipMetadata: (state) => (id) => {
    return state.initialMetadata.enums.relationship.find(
      (relationship) => relationship.id === id
    )
  },
  getRelationshipFamilyMetadata(state) {
    return state.initialMetadata.enums.relationship_family ?? []
  },
  getRelationshipMaritalStatusMetadata(state) {
    return state.initialMetadata.enums.relationship_marital_status ?? []
  },
  // getContactRolesMetadata(state) {
  //   return state.initialMetadata.enums.contactRoles ?? []
  // },
  getCountriesMetadata(state) {
    return state.initialMetadata.enums.countries ?? []
  },
  getLanguagesMetadata(state) {
    return state.initialMetadata.enums.languages ?? []
  },
  getOccupationsMetadata(state) {
    return state.initialMetadata.enums.occupations ?? []
  },
  getSocialStatesMetadata(state) {
    return state.initialMetadata.enums.social_states ?? []
  },
  getAssignmentStatusMetadata(state) {
    return state.initialMetadata.enums.assignement_status ?? []
  },
  getAssignedPersonFunctionsMetadata(state) {
    return state.initialMetadata.models.list_items.users_event_functions ?? []
  },
  getContactRolesMetadata(state) {
    return state.initialMetadata.models.list_items.contact_roles ?? []
  },
  getTypesLocationMetadata(state) {
    return state.initialMetadata.enums.types_location ?? []
  },

  getInitialMetadata(state) {
    return state.initialMetadata
  },
  getOpenModalVersionNote(state) {
    return state.openModalVersionNote
  },
  getMetadataLoaded(state) {
    return state.loaded
  },
  getMetadataInvoicingCategories(state) {
    return state.initialMetadata.models.invoicing_categories
  },
  getMetadataCategoriesNormalized(state) {
    return state.initialMetadata.enums.french_normalized_quotes_categories
  },
  getMetadataNormalizedItemTypes(state) {
    return state.initialMetadata.enums.french_normalized_item_types
  },
  getMetadataMaterialResourcesTypes(state) {
    return state.initialMetadata.enums.material_resource_types
  },
  getMetadataTaskTypes(state) {
    return state.initialMetadata.models.list_items.task_types
  },
  getMetadataItemCategories(state) {
    return state.initialMetadata.models.item_categories
  },
  getMetadataItemCategoriesGroupByParent(state) {
    return state.initialMetadata.models.item_categories_group_by_parent
  },
  getMetadataTasks(state) {
    return state.initialMetadata.models.tasks
  },
  getMetadataTaskCategories(state) {
    return state.initialMetadata.models.list_items.task_categories
  },
  getMetadataTaskStatuses(state) {
    return state.initialMetadata?.enums?.task_status
  },
  getMetadataDossierTypes(state) {
    const defaultTypes = state.initialMetadata?.settings?.dossier_types ?? []
    return (
      state.initialMetadata?.enums?.types?.filter((type) =>
        defaultTypes?.includes(type.id)
      ) ?? []
    )
  },
  getMetadataDossierStatuses(state) {
    return state.initialMetadata?.enums?.dossier_status ?? []
  },
  getMetadataStockStatuses(state) {
    return state.initialMetadata?.enums?.stock_statuses ?? []
  },
  getMetadataIcons(state) {
    return state.initialMetadata?.enums?.icons ?? []
  },
  getMainKey(state) {
    return state.mainKey
  },
  getLanguages(state) {
    return state.initialMetadata.enums.languages
  },
  getDocumentVariables(state) {
    return state.initialMetadata.enums.doc_variables
  },
  /*
        DOSSIER
   */
  getAvailableFilterDossier(state) {
    return state.initialMetadata?.enums?.filter_columns_dossier ?? []
  },
  /*
          AGENDA
     */
  getEventTypesList(state) {
    return state.initialMetadata?.enums?.event_type_list ?? []
  },
  getAvailableFilterAgenda(state) {
    return state.initialMetadata?.enums?.filter_agenda ?? []
  },
  getThemeColors(state) {
    return state.initialMetadata.enums.theme_colors ?? []
  },
  /*
          PLANNING
     */
  getAvailableFilterPlanning(state) {
    return state.initialMetadata?.enums?.filter_planning ?? []
  },

  /*
           SUPPLIER_ORDER
     */
  getAvailableFilterSupplierOrder(state) {
    return state.initialMetadata?.enums?.filter_supplier_order ?? []
  },

  /*
          INVOICING
     */
  getInvoiceDocTypes(state) {
    return state.initialMetadata?.enums?.invoicing_doc_types ?? []
  },
  getInvoiceDocRoles(state) {
    return state.initialMetadata?.enums?.invoicing_doc_roles ?? []
  },
  getAvailableInvoiceDossier(state) {
    return state.initialMetadata?.enums?.filter_columns_invoicing ?? []
  },
  getColumnTypesInvoicingDashboard(state) {
    return state.initialMetadata?.enums?.invoicing.dashboard.column_types ?? []
  },
  getPaymentStatusesMetadata(state) {
    return state.initialMetadata.enums.payment_statuses ?? []
  },
  getPaymentOriginsMetadata(state) {
    return state.initialMetadata.enums.payment_origins ?? []
  },
  getMetadataInvoiceStatus(state) {
    return state.initialMetadata.enums.invoice_status ?? []
  },
  getPaymentTypesMetadata(state) {
    return state.initialMetadata.enums.payment_types ?? []
  },
  getPaymentModesMetadata(state) {
    return state.initialMetadata.enums.payment_modes ?? []
  },
  getTenantModuleBillingTypesMetadata(state) {
    return state.initialMetadata.enums.tenant_module_billing_types ?? []
  },
  /*
        DOCUMENTS
   */
  getDocumentCategoriesMetadata(state) {
    return state.initialMetadata.models?.list_items?.document_categories ?? []
  },
  /*
        DASHBOARD
   */
  getTemplateDashboards(state) {
    return state.initialMetadata?.models?.template_dashboards ?? []
  },
  getTemplateDashboardTypes(state) {
    return state.initialMetadata?.enums?.template_dashboard_types ?? []
  },
  getDashboardCreationTypes(state) {
    return state.initialMetadata?.enums?.dashboard_creation_types ?? []
  },
  getTemplateDashboardByType: (state) => (type) => {
    return (
      state.initialMetadata?.models?.template_dashboards?.filter(
        (item) => item.type === type
      ) ?? []
    )
  },
  /*
        STATISTICS
   */
  getAvailableFilterAnalytics(state) {
    return state.initialMetadata?.enums?.filter_analytics ?? []
  },
  getDashboardListStatistics(state) {
    return state.initialMetadata?.models?.analytics_dashboards ?? []
  },
  getStatsTypes(state) {
    return state.initialMetadata?.enums?.stats_types ?? []
  },
  getChartTypes(state) {
    return state.initialMetadata?.enums?.chart_types ?? []
  },
  getStatsPeriods(state) {
    return state.initialMetadata?.enums?.stats_periods ?? []
  },
  /*
          EVENT
   */
  getEventStatusesMetadata(state) {
    return state.initialMetadata.enums.event_statuses ?? []
  },
  /*
          OPENAI
   */
  getOpenAiEmailMessagesType(state) {
    return state.initialMetadata.enums?.openai?.email_messages_type ?? []
  },
  /*
      SETTINGS
  */
  getOpenAIEnabled(state) {
    return state.initialMetadata?.settings?.openai_enabled ?? false
  },
  getMetadataPriceEncodingType(state) {
    return state.initialMetadata?.settings?.price_encoding_type
  },
  getInvoicingColumns(state) {
    return state.initialMetadata.settings.invoicing_columns
  },
  getThirdPartyColumns(state) {
    return state.initialMetadata.settings.third_party_cost_columns
  },
  getAvailableLanguages(state) {
    return state.initialMetadata.settings.available_languages
  },
  getDefaultLanguage(state) {
    return state.initialMetadata.settings.default_language
  },
  getDefaultCountry(state) {
    return state.initialMetadata.settings.default_country
  },
  getListColors(state) {
    return state.initialMetadata?.enums?.theme_colors ?? []
  },
  getEventsOrder(state) {
    return (
      state.initialMetadata?.settings?.events_order ?? {
        funeral: base_order_funeral,
        remain: base_order_remain
      }
    )
  },

  /*
        APP SETTINGS
   */

  getWebsocketSetting(state) {
    return state.initialMetadata?.app_settings?.websocket ?? null
  },
  getTenantIdSetting(state) {
    return state.initialMetadata?.app_settings?.tenant_id ?? null
  },
  getFunctionalities(state) {
    return state.initialMetadata?.app_settings?.functionalities ?? []
  },

  /*
        ELECTRONIC SIGNATURE
   */

  getElectronicSignatureStatuses(state) {
    return state.initialMetadata?.enums?.electronic_signature?.statuses ?? []
  },
  getElectronicSignatureSignerStatus(state) {
    return (
      state.initialMetadata?.enums?.electronic_signature?.signer_statuses ?? []
    )
  },

  /*
        FORM EDITION
   */
  getGlobalFormCurrentFormSettings(state) {
    return state.global_form.current_form_settings
  },
  getGlobalFormFuneralHomeId(state) {
    return state.global_form.funeral_home_id
  },
  getGlobalFormEditionMode(state) {
    return state.global_form.edition_mode
  },
  /*
      FH SETTINGS
  */
  getFuneralHomeSettings(state) {
    return state.initialMetadata.fh_settings
  },
  /*
      SCREEN
  */
  getVideos(state) {
    return state.initialMetadata.enums.videos ?? []
  },
  getScreenConfigTypes(state) {
    return state.initialMetadata.enums.screen.types ?? []
  },
  /*
    IMPORT
  */
  getImportTypes(state) {
    return state.initialMetadata.enums.import.import_type ?? []
  }
}
