<template>
  <!--#region GenericTypes -->
  <template v-if="formField.type === TypeForm.INPUT">
    <div>
      <BaseSimpleInput
        v-model="localFormValue"
        :label="formField.label"
        :name="formField.name"
        :disabled="disabled"
      />
    </div>
  </template>
  <template v-if="formField.type === TypeForm.INPUT_NUMBER">
    <div>
      <BaseSimpleInput
        v-model="localFormValue"
        :label="formField.label"
        type="number"
        :name="formField.name"
        :disabled="disabled"
      />
    </div>
  </template>
  <template v-else-if="formField.type === TypeForm.TEXTAREA">
    <div>
      <BaseSimpleTextArea
        v-model="localFormValue"
        :label="formField.label"
        :name="formField.name"
        :disabled="disabled"
      />
    </div>
  </template>
  <template v-else-if="formField.type === TypeForm.RADIO">
    <div>
      <BaseSimpleRadioGroup
        v-model="localFormValue"
        :name="formField.name"
        :options="formField.options"
        :label="formField.label"
        :disabled="disabled"
      />
    </div>
  </template>
  <template v-else-if="formField.type === TypeForm.DATEPICKER">
    <BaseShowEditDatePickerv2
      v-model:date="localFormValue"
      :label="formField.label"
      :edition-mode="true"
      :today-default="todayDefault"
      :disabled="disabled"
    />
  </template>
  <template v-else-if="formField.type === TypeForm.SELECT_AJAX">
    <BaseSelectSimpleAjax
      :key="formField"
      v-model="localFormValue"
      :url="formField.url"
      :label="formField.label"
      :name="formField.name"
      :default-ajax="localFormValue"
      :disabled="disabled"
    />
  </template>
  <template v-else-if="formField.type === TypeForm.BOOLEAN_RADIO">
    <BaseSimpleBooleanRadioGroup
      v-model="localFormValue"
      :name="formField.name"
      :label="formField.label"
    />
  </template>
  <template v-else-if="formField.type === TypeForm.CHECKBOX_LIST">
    <BaseShowEditCheckBoxList
      v-model="localFormValue"
      :edition-mode="true"
      :label="formField.label"
      :options="formField.options"
      :is-vertical="true"
      :name="formField.name"
      class="mb-4 mt-3"
      :disabled="disabled"
    />
  </template>
  <!--#endregion -->

  <!--#region  Specific types-->
  <template
    v-else-if="
      [TypeForm.SELECT, 'SelectSettingDefaultMessage'].includes(formField.type)
    "
  >
    <SelectSimpleComponent
      v-model="localFormValue"
      :label="formField.label"
      :name="formField.name"
      :options="formField.options"
      :disabled="disabled"
    />
  </template>
  <template v-else-if="formField.type === TypeForm.SELECT_CONTACT_DOSSIER">
    <BaseContactDossierShowEditSelectSimple
      v-model="localFormValue"
      :name="formField.name"
      :label="formField.label"
      attribute-label="name"
      :disabled="disabled"
      :dossier-id="dossierId"
    />
  </template>
  <template v-else-if="formField.type === TypeForm.SELECT_TRANSFERT_DOSSIER">
    <BaseTransfertDossierShowEditSelectSimple
      v-model="localFormValue"
      :name="formField.name"
      :label="formField.label"
      attribute-label="name"
      :disabled="disabled"
      :dossier-id="dossierId"
    />
  </template>
  <template v-else-if="formField.type === TypeForm.SELECT_CEREMONY_DOSSIER">
    <BaseCeremonyDossierShowEditSelectSimple
      v-model="localFormValue"
      :name="formField.name"
      :label="formField.label"
      attribute-label="name"
      :disabled="disabled"
      :dossier-id="dossierId"
    />
  </template>
  <template v-else-if="formField.type === TypeForm.SELECT_DEMANDEUR_FORM">
    <BaseSelectDemandeurFormSimple
      v-model="localFormValue"
      :name="formField.name"
      :label="formField.label"
      attribute-label="name"
      :disabled="disabled"
      :dossier-id="dossierId"
    />
  </template>
  <template v-else-if="formField.type === TypeForm.SELECT_FUNERAILLES_DOSSIER">
    <BaseFuneraillesSelectSimple
      v-model="localFormValue"
      :name="formField.name"
      :label="formField.label"
      attribute-label="name"
      :disabled="disabled"
      :dossier-id="dossierId"
    />
  </template>
  <!--#endregion -->

</template>

<script>
import BaseSimpleInput from '@c/BaseDynamicFormInput/BaseSimpleInput'
import BaseSimpleRadioGroup from '@c/BaseDynamicFormInput/BaseSimpleRadioGroup'
import BaseSimpleTextArea from '@c/BaseDynamicFormInput/BaseSimpleTextarea'
import { mapGetters } from 'vuex'
import { TypeForm } from '@/assets/enums'
import BaseContactDossierShowEditSelectSimple from '@c/BaseContactDossierShowEditSelectSimple.vue'
import BaseSelectDemandeurFormSimple from '@c/BaseSelectDemandeurFormSimple.vue'
import SelectSimpleComponent from '@c/BaseFormComponent/SelectSimpleComponent.vue'
import BaseSelectSimpleAjax from '@c/addf-package/components/BaseSelect/BaseSelectFormSimple/BaseSelectSimpleAjax.vue'
import BaseSimpleBooleanRadioGroup from '@c/BaseDynamicFormInput/BaseSimpleBooleanRadioGroup.vue'
import BaseShowEditDatePickerv2 from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'
import BaseTransfertDossierShowEditSelectSimple from '@c/BaseTransfertDossierShowEditSelectSimple.vue'
import BaseCeremonyDossierShowEditSelectSimple from '@c/BaseCeremonyDossierShowEditSelectSimple.vue'
import BaseFuneraillesSelectSimple from '@c/BaseFuneraillesSelectSimple.vue'

export default {
  name: 'BaseDynamicFormInput',
  components: {
    BaseFuneraillesSelectSimple,
    BaseCeremonyDossierShowEditSelectSimple,
    BaseTransfertDossierShowEditSelectSimple,
    BaseShowEditDatePickerv2,
    BaseSimpleBooleanRadioGroup,
    BaseSelectSimpleAjax,
    SelectSimpleComponent,
    BaseSelectDemandeurFormSimple,
    BaseContactDossierShowEditSelectSimple,
    BaseSimpleTextArea,
    BaseSimpleRadioGroup,
    BaseSimpleInput
  },
  props: {
    formField: {
      type: Object,
      required: true
    },
    formValue: {
      type: [String, Number],
      required: true
    },
    formFieldIndex: {
      type: [String, Number],
      required: false,
      default: null
    },
    todayDefault: {
      type: Boolean,
      required: false,
      default: false
    },
    ajaxTrackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    ajaxAttributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    radioTrackBy: {
      type: String,
      required: false,
      default: 'name'
    },
    vertical_align: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    dossierId: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['update:formValue'],
  data() {
    return {
      TypeForm: TypeForm
    }
  },
  computed: {
    ...mapGetters({
      metadata: 'metadata/getInitialMetadata'
    }),
    localFormValue: {
      get() {
        return this.formValue
      },
      set(value) {
        this.$emit('update:formValue', value)
      }
    }
  }
}
</script>
