<template>
  <div v-if="editionMode" :class="groupClass">
    <div>
      <SelectComponent
        v-model="internalValue"
        :values="formatedContactList"
        :label="label ? label : $t('attributes.contacts')"
        name="demandeur"
        :required="required"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseEditLabel from '@c/addf-package/components/BaseLabel/BaseEditLabel.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'

export default {
  name: 'BaseContactDossierShowEditSelectSimple',
  components: {
    SelectComponent
  },
  props: {
    name: {
      type: String,
      required: false,
      default: 'contact'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: String,
      required: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    dontDisplayLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    editionMode: {
      type: Boolean,
      default: true
    },
    fullModelResponse: { type: Boolean, required: false, default: false },
    dossierId: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      contacts: []
    }
  },
  computed: {
    formatedContactList() {
      if (
        Array.isArray(this.contacts.contacts) &&
        this.contacts.contacts.length
      ) {
        return this.contacts.contacts.map((contact) => {
          return {
            id: contact.id,
            name: contact.full_name
          }
        })
      }
      return []
    },
    internalValue: {
      get() {
        if (!this.modelValue) {
          return null
        }
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('change', newValue)
      }
    }
  },
  mounted() {
    if (this.$route.params?.id || this.dossierId) {
      this.processFetchContactDossierAction({
        dossier_id: this.$route.params.id || this.dossierId
      }).then((contacts) => (this.contacts = contacts))
    }
  },
  methods: {
    ...mapActions({
      processFetchContactDossierAction: 'dossier/fetchContactDossierList'
    })
  }
}
</script>
