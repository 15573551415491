<template>
  <div
    class="parent-container flex h-full flex-col gap-2 rounded-md border p-3 dark:border dark:border-white dark:bg-slate-700 dark:text-white"
    :class="[
      selectionMode
        ? editionMode
          ? 'cursor-pointer select-none shadow-lg'
          : 'cursor-default select-none shadow-md'
        : editionMode
        ? 'shadow-lg'
        : 'shadow-md',
      selected
        ? editionMode
          ? 'border-2 border-blue-400 border-opacity-30'
          : 'bg-blue-50'
        : 'shadow-lg'
    ]"
    @click="handleClickOnTitleOrAvatar"
  >
    <div class="flex w-full flex-nowrap items-center justify-between gap-2">
      <!--#region avatar + main infos -->
      <div style="max-width: 89%">
        <!--#region avatar -->
        <slot v-if="photo" name="card-avatar" :entry="entry">
          <button>
            <img
              class="h-8 w-8 rounded-full shadow-md ring-2 ring-white"
              :src="cPhoto"
              :alt="cTitle"
            />
          </button>
        </slot>
        <!--#endregion -->
        <!--#region mainInfos -->
        <div>
          <div
            :title="cTitle"
            class="group relative flex flex-nowrap items-center gap-2"
          >
            <h2
              class="select-none truncate text-base font-semibold capitalize-first"
            >
              {{ cTitle }}
            </h2>
            <!-- Tooltip -->
            <span
              class="-translate-x-1/10 absolute bottom-0 -translate-y-10 transform rounded-lg bg-gray-700 px-2 py-1 text-xs text-white opacity-0 transition-opacity group-hover:opacity-100"
            >
              {{ cTitle }}
            </span>
            <FontAwesomeIcon
              v-if="titleIcon && !entry.is_complete"
              :icon="['fal', 'triangle-exclamation']"
              class="text-red-700"
            />
          </div>
          <div
            v-if="cSubtitle"
            class="mt-0.5 select-none text-xs text-gray-600"
          >
            <p v-text="cSubtitle" />
          </div>
        </div>
        <!--#endregion -->
      </div>
      <div
        v-if="options?.length > 0"
        ref="dropDownMenuContainer"
        class="container-drop-down"
      >
        <BaseDropDownMenu
          :options="options"
          :is-button="false"
          size="small"
          :entry="entry"
        />
      </div>
      <!--#endregion -->
    </div>
    <div
      v-if="$slots['card-details'] || $slots['card-selected']"
      class="gap-2 px-2 pb-2"
    >
      <div class="flex">
        <slot name="card-details" :entry="entry" />
      </div>
      <div class="flex">
        <slot
          v-if="selected && editionMode"
          name="card-selected"
          :entry="entry"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseDropDownMenu from '@c/addf-package/components/dropdown/BaseDropDownMenu.vue'
import loginPage from '@/views/login/LoginPage.vue'

export default {
  name: 'BaseCard',
  components: { BaseDropDownMenu, FontAwesomeIcon },
  props: {
    title: {
      type: String,
      required: true
    },
    titleIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    subtitle: {
      type: String,
      required: false,
      default: null
    },
    photo: {
      type: String,
      required: false,
      default: null
    },
    entry: {
      type: Object,
      required: true
    },
    selectEntry: {
      type: Function,
      required: false,
      default: () => {
        return null
      }
    },
    options: {
      type: Array,
      required: false,
      default: null
    },
    selectionMode: {
      type: Boolean,
      required: false
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    editionMode: {
      type: Boolean,
      required: false
    }
  },
  emits: ['cardSelected'],
  computed: {
    cTitle() {
      return this.title?.split('.').reduce((a, b) => a[b], this.entry) ?? null
    },
    cSubtitle() {
      return (
        this.subtitle?.split('.').reduce((a, b) => a[b], this.entry) ?? null
      )
    },
    cPhoto() {
      return this.photo?.split('.').reduce((a, b) => a[b], this.entry) ?? null
    }
  },
  methods: {
    handleClickOnCard(event) {
      if (!this.selectionMode) {
        return
      }

      if (this.editionMode) {
        return this.$emit('cardSelected', this.entry)
      }
    },
    /**
     * Click on the entire CARD
     * Prevents execution if dropdown is clicked
     * @param event
     */
    handleClickOnTitleOrAvatar(event) {
      if (this.isDropdownContent(event.target)) {
        return
      }

      if (this.selectionMode) {
        return
      }

      this.selectEntry(this.entry)
    },
    /**
     * Checks whether the click is made on the dropdown
     * @param {Element} element
     * @return {boolean}
     */
    isDropdownContent(element) {
      if (this.$refs.dropDownMenuContainer === element) {
        return true
      } else if (element.localName !== 'body') {
        return this.isDropdownContent(element.parentElement)
      }

      return false
    }
  }
}
</script>
