<template>
  <BaseSlideOverModal v-model:is-modal-open="localOpen" width="2xl">
    <template #cta>
      <template v-if="current_view === 'list'">
        <BaseButton
          :title="$t('button.add_new_m', { var: $t('global.contact') })"
          icon="plus"
          theme-color="dark:text-primary dark:hover:bg-primary-900 dark:border-primary-500 dark:border"
          size="small"
          @click="handleCreatePersonButton"
        />
      </template>
      <template v-else-if="current_view === 'form'">
        <div class="flex gap-x-3">
          <BaseButton
            theme-color="bg-secondary text-contrast-secondary dark:bg-slate-700  dark:text-secondary dark:hover:bg-secondary-900 dark:border-secondary-500 dark:border "
            :title="$t('button.cancel')"
            icon="long-arrow-left"
            @click="resumeToList"
          />
          <BaseButton
            :title="formButtonTitle"
            theme-color="dark:text-primary dark:hover:bg-primary-900 dark:border-primary-500 dark:border"
            icon="paper-plane"
            size="small"
            @click="SubmitForm"
          />
        </div>
      </template>
    </template>
    <template #body>
      <div class="flex flex-1 flex-col overflow-y-scroll">
        <Transition
          mode="out-in"
          enter-active-class="duration-300 ease-out"
          enter-from-class="transform translate-x-6 opacity-0"
          enter-to-class="opacity-100 translate-x-0"
          leave-active-class="duration-200 ease-in"
          leave-from-class="opacity-100 translate-x-0"
          leave-to-class="transform opacity-0 -translate-x-6"
        >
          <SelectPersonModalList
            v-if="current_view === 'list'"
            v-model:person-id="localPersonId"
            v-model:person="localPerson"
            :suggested-people="suggestedPeople"
            @edit-person="handleEditPerson"
            @selected="localOpen = false"
          />
          <SelectPersonModalForm
            v-else-if="current_view === 'form'"
            :person="selectedPerson"
          />
        </Transition>
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SelectPersonModalList from '@ui/modal/Person/components/selectPersonModal/SelectPersonModalList'
import SelectPersonModalForm from '@ui/modal/Person/components/selectPersonModal/SelectPersonModalForm'
import BaseSlideOverModal from '@c/addf-package/components/modal/BaseSlideOverModal'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'SelectPersonModal',
  components: {
    BaseButton,
    BaseSlideOverModal,
    SelectPersonModalForm,
    SelectPersonModalList
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    personId: {
      type: String,
      required: false,
      default: null
    },
    person: {
      type: Object,
      required: false,
      default: null
    },
    suggestedPeople: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: [
    'close',
    'update:person',
    'update:person-id',
    'update:open',
    'change'
  ],
  data() {
    return {
      current_view: 'list',
      selectedPerson: null
    }
  },
  computed: {
    ...mapGetters({
      person: 'dossier/getPerson'
    }),
    localOpen: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      }
    },
    localPerson: {
      get() {
        return this.person
      },
      set(value) {
        this.$emit('update:person', value)
      }
    },
    localPersonId: {
      get() {
        return this.personId
      },
      set(value) {
        this.$emit('update:person-id', value)
      }
    },
    formButtonTitle() {
      if (!this.person) return this.$t('button.save')
      return this.person.id
        ? this.$t('button.update_m', { var: this.$t('global.contact') })
        : this.$t('button.save_new_m', { var: this.$t('global.contact') })
    }
  },
  methods: {
    ...mapActions({
      processStorePersonAction: 'person/storePerson',
      processUpdatePersonAction: 'person/updatePerson',
      processFetchPersonAction: 'person/fetchPersonById'
    }),
    handleCreatePersonButton() {
      this.current_view = 'form'
    },
    SubmitForm() {
      if (this.selectedPerson.id) {
        this.processUpdatePersonAction({
          person: this.selectedPerson,
          id: this.selectedPerson.id
        }).then((response) => {
          this.$h.toastSuccess(this.$t('toasts.contact_updated'))
          this.selectedPerson = null
          this.current_view = 'list'
        })
      } else {
        this.processStorePersonAction({
          person: this.selectedPerson
        }).then((response) => {
          this.$h.toastSuccess(this.$t('toasts.contact_created'))
          this.selectedPerson = null
          this.current_view = 'list'
        })
      }
    },
    close() {
      this.$emit('close')
    },
    handleEditPerson(selectedPerson) {
      this.processFetchPersonAction({
        person_id: selectedPerson.id
      }).then((response) => {
        this.selectedPerson = response.person
        this.current_view = 'form'
      })
    },
    resumeToList() {
      this.selectedPerson = null
      this.current_view = 'list'
    }
  }
}
</script>
