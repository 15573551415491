<template>
  <div class="">
    <div
      v-if="supplierOrder"
      class="-mr-1 flex flex-col gap-y-2 px-4 space-y-2"
    >
      <h3 class="text-2xl font-bold text-gray-700 capitalize-first">
        {{ supplierOrder.id ? supplierOrder?.recipient?.name : $t('global.new_supplier_order') }}
      </h3>
      <div class="my-2 z-100">
        <StatusInput
          v-model="supplierOrder.status"
          :options="options"
        />
      </div>
<!--      <WalkmanButton-->
<!--        v-model="supplierOrder.recipient_type"-->
<!--        :label="$t('attributes.order_type')"-->
<!--        :required="true"-->
<!--        :options="initialMetadata?.enums?.supplier_types"-->
<!--        track-by="id"-->
<!--        attribute-label="name"-->
<!--        :errors="errors?.recipient_type"-->
<!--      />-->
<!--      <div-->
<!--        v-if="errors?.recipient_type"-->
<!--        class="form-help text-red-600 -mt-3"-->
<!--      >-->
<!--        {{ errors.recipient_type.join(',') }}-->
<!--      </div>-->
      <selectSupplier
        v-if="
          initialMetadata?.models?.suppliers &&
          supplierOrder.recipient_type === InvoiceableType.SUPPLIER
        "
        v-model="supplierOrder.recipient_id"
        edition-mode
        :label="$t('attributes.supplier')"
        error-key="recipient_id"
        required
        size="small"
        :errors="errors.recipient_id"
      />
      <SelectCompany
        v-if="
                initialMetadata?.models?.companies &&
                supplierOrder.recipient_type === InvoiceableType.COMPANY
              "
        v-model="supplierOrder.recipient_id"
        name="company"
        error-key="company"
        edition-mode
        required
        :errors="errors.recipient_id"
      />
      <SelectFuneralHome
        v-model="supplierOrder.funeral_home_id"
        edition-mode
        :label="$t('attributes.funeral_home')"
        error-key="no-error"
        required
        size="small"
        :errors="errors.funeral_home_id"
      />
      <div v-if="supplierOrder.funeral_home_id" class="relative">
        <DeliveryPlaceListLocations
          :supplier-order="supplierOrder"
        />
      </div>
      <BaseShowEditDateTimePicker
        v-model:date="supplierOrder.delivery_date_time"
        v-model:time-undefined="supplierOrder.delivery_date_time_undefined_time"
        :label-date="$t('global.estimate_delivery_date')"
        name="delivery_date_time"
        :edition-mode="true"
        :errors="errors?.delivery_date_time"
      />
      <BaseShowEditTextArea
        v-model="supplierOrder.delivery_remarks"
        class="col-span-2 col-start-1"
        name="delivery_remarks"
        :label="$t('attributes.delivery_remarks')"
        :errors="errors.delivery_remarks"
        :edition-mode="true"
       />

      <BaseShowEditTextArea
        v-model="supplierOrder.remarks"
        class="col-span-2 col-start-1"
        name="remarks"
        :label="$t('attributes.remarks')"
        :errors="errors.remarks"
        :edition-mode="true"
      />
      <BaseShowEditTextArea
        v-model="supplierOrder.note_intern"
        class="col-span-2 col-start-1"
        :label="$t('attributes.internal_note')"
        name="note_intern"
        :errors="errors.note_intern"
        :edition-mode="true"
      />

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {DocTypes, InvoiceableType} from '@/assets/enums'
import WalkmanButton from '@c/WalkmanButton'
import BaseShowEditDateTimePicker
  from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDateTimePickerv2.vue'
import StatusInput from "@c/addf-package/components/status/StatusInput.vue";
import DeliveryPlaceListLocations from "@ui/modal/supplierOrder/components/DeliveryPlaceListLocations.vue";
import SelectFuneralHome from "@c/InitialStateFormComponent/SelectFuneralHome.vue";
import SelectSupplier from "@c/InitialStateFormComponent/SelectSupplier.vue";
import SelectCompany from "@c/InitialStateFormComponent/SelectCompany.vue";
import BaseShowEditTextArea from "@c/addf-package/components/BaseShowEditInput/BaseShowEditTextArea.vue";

export default {
  name: 'SupplierOrderForm',
  components: {
    BaseShowEditTextArea,
    SelectCompany,
    SelectSupplier,
    SelectFuneralHome,
    DeliveryPlaceListLocations,
    StatusInput,
    BaseShowEditDateTimePicker,
    WalkmanButton,
  },
  props: {
    supplierId: {
      type: [String, Number],
      required: false,
      default: null
    },
    item: {
      type: Object,
      required: true
    },
    needToFetch: {
      type: Boolean,
      required: true
    },
    dossier: {
      type: Object,
      required: false,
      default: null
    },
  },
  emits: ['switch-supplier-order'],
  data() {
    return {
      InvoiceableType: InvoiceableType,
      DocTypes: DocTypes,
      selectedSupplierOrderItem: null,
      selectedLocation: null
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      supplierOrder: 'supplierOrder/getSupplierOrder',
      errors: 'auth/getErrors'
    }),
    options() {
      console.log(this.initialMetadata?.enums?.supplier_order_status)
      return this.initialMetadata?.enums?.supplier_order_status?.filter(status => ['DRA', 'LIT', 'SEN']?.includes(status.id))
    }
  },
  beforeMount() {
    if (this.needToFetch) {
      if (!this.supplierOrder) {
        this.processFetchNewSupplierOrderAction().then((response) => {
          this.$emit('switch-supplier-order', response.supplierOrder.id)
        })
      }
    }
  },
  methods: {
    ...mapActions({
      processFetchNewSupplierOrderAction: 'supplierOrder/fetchNewSupplierOrder',
      processUpdateInvoiceAction: 'invoicing/updateInvoice',
      processFetchInvoiceIDAction: 'invoicing/fetchInvoiceID',
      processFetchNewInvoiceAction: 'invoicing/fetchNewInvoice',
      processChangeInvoiceStatusAction: 'invoicing/changeInvoiceStatus',
      processFetchItemsDossier: 'dossier/fetchItemsDossier'
    }),
    ...mapMutations({
      processSetInvoiceInvoiceableIDMutation:
        'invoicing/setInvoiceInvoiceableID'
    }),
    setInvoiceableID(id) {
      this.processSetInvoiceInvoiceableIDMutation(id)
    },
    editSupplierOrderItem(supplierOrderItem) {
      this.selectedSupplierOrderItem = supplierOrderItem
    }
  }
}
</script>
